
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import { get_user, save_shipping_address } from '../utils'

export default function ShippingAddressScreen(props) {
    const userSignin = useSelector(state => state.userSignin)
 
    const {userInfo} = userSignin

    //const cart = useSelector(state => state.cart)
    // const {shippingAddress} = cart

    // const userAddressMap = useSelector(state => state.userAddressMap)
    // const {address: addressMap} = userAddressMap

    // const [lat, setLat] = useState(shippingAddress.lat)
    // const [lng, setLng] = useState(shippingAddress.lng)

    if(!userInfo){
        props.history.push('/signin')
    }

    // const [full_name, set_full_name] = useState(shippingAddress.full_name)
    // const [address, set_address] = useState(shippingAddress.address)
    // const [city, set_city] = useState(shippingAddress.city)
    // const [postal_code, set_postal_code] = useState(shippingAddress.postal_code)
    // const [country, set_country] = useState(shippingAddress.country)

    const [full_name, set_full_name] = useState("")
    const [address, set_address] = useState("")
    const [city, set_city] = useState("")
    const [postal_code, set_postal_code] = useState("")
    const [country, set_country] = useState("")
    const dispatch = useDispatch()
    const [user, set_user] = useState(null)

    const [update_error, set_update_error] = useState("")
    const [loading_user, set_loading_user] = useState(false)
    const [loading_user_error, set_loading_user_error] = useState("")

    useEffect(() => {
        if (user){
            set_full_name(user.shipping_full_name)
            set_address(user.shipping_address)
            set_postal_code(user.shipping_postal_code)
            set_city(user.shipping_city)
            set_country(user.shipping_country)
        }
    }, [user])

    useEffect(() => {
        get_user(userInfo, set_user, set_loading_user_error, set_loading_user)
    }, [userInfo])


    const submitHandler = (e) => {
        e.preventDefault()
        // const newLat = addressMap ? addressMap.lat : lat
        // const newLng = addressMap ? addressMap.lng : lng
        // if (addressMap) {
        //     setLat(addressMap.lat)
        //     setLng(addressMap.lng)
        // }
        //let moveOn = true
        // if (!newLat || !newLng) {
        //     moveOn = window.confirm("You did not set your location on map. Continue?" )
        // }
        //if (moveOn){
        console.log("Submit shipping address")
        dispatch(saveShippingAddress({ // Save to cart
            fullName: full_name, 
            address, 
            city, 
            postalCode: postal_code, 
            country
            // lat: newLat,
            // lng: newLng
        }))
        const new_shipping_address = { //Save to db
            _id: userInfo._id,
            shipping_full_name: full_name,
            shipping_address: address,
            shipping_postal_code: postal_code,
            shipping_city: city,
            shipping_country: country
        }
        save_shipping_address(userInfo, new_shipping_address, set_user, set_update_error) 
        props.history.push('/payment')
        //}
        // const user_signin = get_user_signin()
		// console.log("ShippingAddressScreen: userSignin is: "+JSON.stringify(user_signin)+". set user_signin to "+userSignin)
		// set_user_signin(userSignin)
    }

    // const chooseOnMap = () => {
    //     dispatch(saveShippingAddress({
    //         fullName, 
    //         address, 
    //         city, 
    //         postalCode, 
    //         country,
    //         lat,
    //         lng
    //     }))
    //     props.history.push('/map')
    //}

    console.log("ShippingAddressScreen")

	return (
		<div>
            <CheckoutSteps step1 step2></CheckoutSteps>
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>Shipping Address</h1>
                </div>
                <div>
                    <label htmlFor="fullName">Full Name</label>
                    <input type="text" id="fullName" placeholder="Enter full name" value={full_name} onChange={(e) => set_full_name(e.target.value)} required ></input>
                </div>
                <div>
                    <label htmlFor="address">Address</label>
                    <input type="text" id="address" placeholder="Enter address" value={address} onChange={(e) => set_address(e.target.value)} required ></input>
                </div>
                <div>
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" placeholder="Enter city" value={city} onChange={(e) => set_city(e.target.value)} required ></input>
                </div>
                <div>
                    <label htmlFor="postalCode">Postal code</label>
                    <input type="text" id="postalCode" placeholder="Enter postal code" value={postal_code} onChange={(e) => set_postal_code(e.target.value)} required ></input>
                </div>
                <div>
                    <label htmlFor="country">Country</label>
                    <input type="text" id="country" placeholder="Enter country" value={country} onChange={(e) => set_country(e.target.value)} required ></input>
                </div>
                {/* <div>
                    <label htmlFor="chooseOnMap">Location</label>
                    <button type="button" onClick={chooseOnMap}>
                        Choose On Map
                    </button>
                </div> */}
                <div>
                    <label></label>
                    <button className="primary" type="submit">Continue</button>
                </div>
            </form>
		</div>
	)

}