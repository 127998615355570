import axios from '../node_modules/axios/index'
import { signout } from './actions/userActions'

export const prices = [
    {name: 'Any', min: 0, max: 0},
    {name: 'SEK 1-10', min: 1, max: 10},
    {name: 'SEK 10-100', min: 10, max: 100},
    {name: 'SEK 100-1000', min: 100, max: 1000}
]

export const ratings = [
    {name: '4 stars & up', rating: 4},
    {name: '3 stars & up', rating: 3},
    {name: '2 stars & up', rating: 2},
    {name: '1 star & up', rating: 1}
]

export function get_plan_description(plan_nbr){
    switch(plan_nbr){
        case '1': return "Gratis, max 5 produkter."
        case '2': return "Egen domän"
        case '3': return "Domän ingår"
        default: return "Inget"
    }
}

export function reformat_timestamp(timestamp){
    if (timestamp){
        const date = timestamp.substring(0, 10)
        const time = timestamp.substring(11, 19)
        return date + " " + time
    }
    else return "-"
}

export async function get_domain_seller(domain, success_callback){
    try {
        const {data} = await axios.get(`/api/users/domain_seller/${domain}`) // Token kollas ej av backend
        if (success_callback) success_callback(data)
    }
    catch(e){
        
    }
}

export async function update_order(userInfo, order_id, updates, success_callback, error_callback){
    try {
        const {data} = await axios.put(`/api/orders/${order_id}/update`, updates, {headers: { Authorization: `Bearer ${userInfo?.token}`}}) 
        if (success_callback) success_callback(data)
    }
    catch(e){
        if (error_callback) error_callback(e.response && e.response.data.message ? e.response.data.message : e.message)
    }
}

export async function update_settings(userInfo, updates, success_callback, error_callback, set_loading){

    try {
        const {data} = await axios.put(`/api/settings/`, updates, {headers: { Authorization: `Bearer ${userInfo?.token}`}}) 
        if (success_callback) success_callback(data)
    }
    catch(e){
        if (error_callback) error_callback(e.response && e.response.data.message ? e.response.data.message : e.message)
    }
}

export async function get_settings(userInfo, success_callback, error_callback, set_loading){
    const loading_timeout = setTimeout(function(){ set_loading(true)}, 500);
    try {
        const {data} = await axios.get(`/api/settings`, {headers: { Authorization: `Bearer ${userInfo?.token}`}}) 
        if (success_callback) success_callback(data)
    }
    catch(e){
        if (error_callback) error_callback(e.response && e.response.data.message ? e.response.data.message : e.message)
    }
    clearTimeout(loading_timeout)
    set_loading(false)
}

export async function get_user(userInfo, success_callback, error_callback, set_loading){
    const loading_timeout = setTimeout(function(){ set_loading(true)}, 500);
    try {
        const {data} = await axios.get(`/api/users/${userInfo._id}`, {headers: { Authorization: `Bearer ${userInfo?.token}`}}) // Token kollas ej av backend
        if (success_callback) success_callback(data)
    }
    catch(e){
        if (error_callback) error_callback(e.response && e.response.data.message ? e.response.data.message : e.message)
    }
    clearTimeout(loading_timeout)
    set_loading(false)
}

export async function save_shipping_address(user_info, updates, success_callback, error_callback) {
    try {
        const {data} = await axios.put(`/api/users/save_shipping_address`, updates, {
            headers: {Authorization: `Bearer ${user_info.token}`}
        })
        if (success_callback) success_callback(data)
    }
    catch(e){
        if (error_callback) error_callback(e.response && e.response.data.message ? e.response.data.message : e.message)
    }
}

export function check_auth(error, dispatch){
    if (error.response && error.response.status === 401) dispatch(signout()) 
}



let testvar = 0
let user_signin = {}

export function set_testvar(t){
    testvar = t
    console.log("set_testvar testvar: "+testvar)
}

export function get_testvar(){
    console.log("get_testvar testvar: "+testvar)
    return testvar
}

export function get_user_signin(){
    console.log("get_user_signin: "+JSON.stringify(user_signin))
    return user_signin
}

export function set_user_signin(u){
    user_signin = u
    console.log("set_user_signin: "+JSON.stringify(user_signin))
}