import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch  from 'react-switch'
import { detailsUser, updateUser} from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_UPDATE_RESET } from '../constants/userConstants'

export default function UserEditScreen(props) {
    const userId = props.match.params.id
   
    const [is_seller, set_is_seller] = useState(false)
    const [is_admin, set_is_admin] = useState(false)
    const [domain, set_domain] = useState('')
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.userDetails)
    const {loading, error, user} = userDetails
    const userUpdate = useSelector(state => state.userUpdate)
    const {loading: loadingUpdate, error: errorUpdate, success: successUpdate} = userUpdate

    useEffect(() => {
        if (successUpdate) {
            dispatch({type: USER_UPDATE_RESET})
            props.history.push('/userlist')
        }
        if(user){
            
            set_is_seller(user.is_seller)
            set_is_admin(user.is_admin)
            set_domain(user.seller.domain)
        }
        else {
            dispatch(detailsUser(userId))
        }
    }, [dispatch, props.history, successUpdate, user, userId])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser({_id: userId, is_seller, is_admin: is_admin, seller_domain: domain}))
    }

    console.log("UserEditScreen")
	return (
		<div className="settings">
            <form className="form" onSubmit={submitHandler}>
                <div>
                <h1 className="theme-header">Edit User</h1>
                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
                
                </div>
                {
                    loading ? <LoadingBox></LoadingBox> :
                    error ? <MessageBox variant="danger">{error}</MessageBox> :
                    user && 
                    <>
                         <div className="card p-2">
                            <h3>User</h3>
                            <div className="p-1">
                                <label htmlFor="name" className="form-label">Name</label> 
                                <div id="name">{user.name}</div>
                            
                            </div>
                            <div className="p-1">
                                <label htmlFor="email" className="form-label">Email</label> 
                                <div id="email">{user.email}</div>
                                
                            </div>
                        </div>
                       
                        {(user.is_seller || user.wants_to_be_seller) && (
                        
                            <div className="card p-2">
                                <h3>Shop</h3>
                                <div className="p-1">
                                    <label htmlFor="seller_name" className="form-label">Name</label>
                                    <div id="seller_name">{user.seller.name} </div> 
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_address" className="form-label">Address</label>
                                    <div id="seller_address">{user.seller.address}</div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_post_number" className="form-label">Postal code</label>
                                    <div id="seller_post_number">{user.seller.post_number}</div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_city" className="form-label">City</label>
                                    <div id="seller_city" >{user.seller.city}</div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_phone" className="form-label">Phone</label>
                                    <div id="seller_phone" >{user.seller.phone} </div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_organisation_number" className="form-label">Organisation number</label>
                                    <div id="seller_organisation_number" > {user.seller.organisation_number} </div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="shop_status" className="form-label">Status</label>
                                    {user.is_seller ? <div id="shop_status" className="shop-open">Open</div> : 
                                    user.wants_to_be_seller ? <div id="shop_status" className="pending">Waiting for approval</div>  : 
                                    <div id="shop_status" className="danger">Closed</div>}
                                </div>
                                <div className="p-1">
                                    <label htmlFor="shop_url" className="form-label">URL</label>
                                    <div id="shop_url">
                                        <a className="break-all" href={`https://lundazon.se/seller/${userId}`}>{`https://lundazon.se/seller/${userId}`}</a>
                                    </div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="shop_plan" className="form-label">Plan</label>
                                    <div id="shop_plan">   {user.seller.plan} </div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_has_swish" className="form-label">Swish</label>  
                                    <div id="seller_has_swish">{user.seller.has_swish ? 'YES' : 'NO'} </div> 
                                </div>
                                <div className="p-1">
                                    <label htmlFor="seller_swish_number" className="form-label">Swish number</label> 
                                    <div id="seller_swish_number">{user.seller.swish_number} </div>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="domain_input" className="form-label">Domain</label> 
                                    <div><input id="domain_input" type="text" value={domain}  onChange={(e) => set_domain(e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="card p-2">
                        <h3 className="theme-header">Wants to be seller</h3> 
                        <div>
                            <label>{user.wants_to_be_seller ? 'YES' : 'NO'}
                            </label> 
                        </div>
                        </div>
                    
                         <div className="card p-2">
                         <h3  className="theme-header">Seller</h3> 
                         <label className="logo_switch">
                            <Switch onColor="#44a775" uncheckedIcon={false} checkedIcon={false} onChange={() => set_is_seller(!is_seller)} checked={is_seller}/>
                        </label>

                            {/* <label><input id="is_seller" type="checkbox" checked={is_seller} 
                            onChange={(e) => set_is_seller(e.target.checked)}>
                            </input> Is seller</label>  */}
                        </div>
                        
                        <div className="card p-2">
                        <h3  className="theme-header">Admin</h3> 
                        <label className="logo_switch">
                            <Switch onColor="#44a775" uncheckedIcon={false} checkedIcon={false} onChange={() => set_is_admin(!is_admin)} checked={is_admin}/>
                        </label>

                            {/* <label><input id="is_admin" type="checkbox"  checked={is_admin}
                            onChange={(e) => set_is_admin(e.target.checked)}>
                            </input> Is admin
                            </label>  */}
                        </div>
                        <div>
                            <button className="primary" type="submit">Update</button>
                        </div>
                    </>
                }

            </form>
		</div>
	)

}