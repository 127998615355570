import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { get_settings, update_settings } from '../utils'

export default function SellerProfileScreen(props) {
  
  
    const [about_text, set_about_text] = useState("")
    const [privacy_text, set_privacy_text] = useState("")
    const [become_seller_text_1, set_become_seller_text_1] = useState("")
    const [become_seller_text_2, set_become_seller_text_2] = useState("")
    const [privacy_policy_text, set_privacy_policy_text] = useState("")
    
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const dispatch = useDispatch()

    const [loading, set_loading] = useState(false)
    const [error, set_error] = useState(false)
    const [loading_update, set_loading_update] = useState(false)
    const [error_update, set_error_update] = useState(false)
    const [success_update, set_success_update] = useState(false)

    useEffect(() => {
        get_settings(userInfo, set_settings, set_error, set_loading)
    }, [userInfo])

    function set_settings(data){
        set_about_text(data.about_text)
        set_privacy_text(data.privacy_text)
        set_become_seller_text_1(data.become_seller_text_1)
        set_become_seller_text_2(data.become_seller_text_2)
        set_privacy_policy_text(data.privacy_policy_text)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        update_settings(userInfo, { about_text, privacy_text, become_seller_text_1, become_seller_text_2, privacy_policy_text }, set_success_update, set_error_update, set_loading_update)
    }


    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [showSnackbarMessage, setShowSnackbarMessage] = useState(false)

    useEffect(() => {
        console.log("useEffect successUpdate: "+success_update)
        if (success_update){
            setShowSnackbarMessage(true)
            setSnackbarMessage("Settings were updated.")
            setTimeout(function(){ setShowSnackbarMessage(false)}, 3000);
        }
    },[success_update])

    useEffect(() => {
        if (!showSnackbarMessage)   set_success_update(null)
    },[showSnackbarMessage])


	return (
		<div className="admin-settings">
		 <form className="form" onSubmit={submitHandler}>
            
            
            {
                loading ? <LoadingBox></LoadingBox>
                : error ? <MessageBox variant="danger">{error}</MessageBox>
                :
                <>
                {loading_update && <LoadingBox></LoadingBox>}
                {error_update && (<MessageBox variant="danger">{error_update}</MessageBox>)}
                {/* {success_update && <MessageBox variant="success">Settings Updated Successfully</MessageBox>} */}
                
             
                    <>
                   
                    <h1 className="p-2 theme-header">Admin / Settings</h1>

                   
                    <div className="card p-1 form-card">
                        <h3>About text</h3>
                        <textarea id="about_text" rows="10" type="text" placeholder="Enter About text"
                         value={about_text} onChange={(e) => set_about_text(e.target.value)}>
                         </textarea>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Privacy text</h3>
                        <textarea id="about_text" rows="10" type="text" placeholder="Enter Privacy text"
                         value={privacy_text} onChange={(e) => set_privacy_text(e.target.value)}>
                         </textarea>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Become seller text I</h3>
                        <textarea id="become_seller_text" rows="10" type="text" placeholder="Enter Become seller text I"
                         value={become_seller_text_1} onChange={(e) => set_become_seller_text_1(e.target.value)}>
                         </textarea>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Become seller text II</h3>
                        <textarea id="become_seller_text_2" rows="10" type="text" placeholder="Enter Become seller text II"
                         value={become_seller_text_2} onChange={(e) => set_become_seller_text_2(e.target.value)}>
                         </textarea>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Privacy Policy text</h3>
                        <textarea id="policy_text" rows="10" type="text" placeholder="Enter Privacy Policy text"
                         value={privacy_policy_text} onChange={(e) => set_privacy_policy_text(e.target.value)}>
                         </textarea>
                    </div>

                    <div>
                        <label/>
                        <button className="primary" type="submit">Update</button>
                    </div>
                    </>
                    
                
                
             
                </>
            }
        
        </form>
        <div className={showSnackbarMessage ? 'show' : ''}  id="snackbar">{snackbarMessage}</div>
        
    </div>
	)

}