import { 
    ORDER_CREATE_FAIL, 
    ORDER_CREATE_REQUEST, 
    ORDER_CREATE_RESET, 
    ORDER_CREATE_SUCCESS, 
    ORDER_DELETE_FAIL, 
    ORDER_DELETE_REQUEST, 
    ORDER_DELETE_RESET, 
    ORDER_DELETE_SUCCESS, 
    ORDER_DELIVER_FAIL, 
    ORDER_DELIVER_REQUEST, 
    ORDER_DELIVER_RESET, 
    ORDER_DELIVER_SUCCESS, 
    ORDER_DETAILS_FAIL, 
    ORDER_DETAILS_REQUEST, 
    ORDER_DETAILS_SUCCESS, 
    ORDER_LIST_FAIL, 
    ORDER_LIST_REQUEST, 
    ORDER_LIST_SUCCESS, 
    ORDER_MINE_LIST_FAIL, 
    ORDER_MINE_LIST_REQUEST, 
    ORDER_MINE_LIST_SUCCESS, 
    ORDER_PAY_FAIL, 
    ORDER_PAY_REQUEST, 
    ORDER_PAY_RESET, 
    ORDER_PAY_SUCCESS 
} from "../constants/orderConstants"

export const orderCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case ORDER_CREATE_REQUEST:
              //return {loading: true}
              return state
        case ORDER_CREATE_SUCCESS:
            return {loading: false, success: true, order: action.payload}
        case ORDER_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ORDER_CREATE_RESET:
            return {};
        default:
            return state
    }
}

export const orderDetailsReducer = (state = {loading: true}, action) => {
    switch (action.type){
        case ORDER_DETAILS_REQUEST:
              //return {loading: true}
              return state
        case ORDER_DETAILS_SUCCESS:
            return {loading: false, order: action.payload}
        case ORDER_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const orderPayReducer = (state = {}, action) => {
    switch (action.type){
        case ORDER_PAY_REQUEST:
              //return {loading: true}
              return state
        case ORDER_PAY_SUCCESS:
            return {loading: false, success: true}
        case ORDER_PAY_FAIL:
            return {loading: false, error: action.payload}
        case ORDER_PAY_RESET:
            return {}
        default:
            return state
    }
}

export const orderMineListReducer = ( state = { orders: []}, action) => {
    switch(action.type) {
        case ORDER_MINE_LIST_REQUEST:
            console.log("orderMineListReducer REQUEST orders: "+JSON.stringify(action.payload))
            //return {loading: true}
            return state
        case ORDER_MINE_LIST_SUCCESS:
            console.log("orderMineListReducer SUCCESS")
            return {loading: false, orders: action.payload}
        case ORDER_MINE_LIST_FAIL:
            console.log("orderMineListReducer FAIL")
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const orderListReducer = ( state = { orders: []}, action) => {
    switch(action.type) {
        case ORDER_LIST_REQUEST:
            console.log("orderListReducer REQUEST orders: "+JSON.stringify(action.payload))
            //return {loading: true}
            return state
        case ORDER_LIST_SUCCESS:
            console.log("orderListReducer SUCCESS")
            return {loading: false, orders: action.payload}
        case ORDER_LIST_FAIL:
            console.log("orderListReducer FAIL")
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const orderDeleteReducer = ( state = { }, action) => {
    switch(action.type) {
        case ORDER_DELETE_REQUEST:
            console.log("orderDelete REQUEST orders: "+JSON.stringify(action.payload))
            //return {loading: true}
            return state
        case ORDER_DELETE_SUCCESS:
            console.log("orderDelete SUCCESS")
            return {loading: false, success: true}
        case ORDER_DELETE_FAIL:
            console.log("orderDelete FAIL")
            return {loading: false, error: action.payload}
        case ORDER_DELETE_RESET:
            console.log("orderDelete RESET")
            return {}
        default:
            return state
    }
}

export const orderDeliverReducer = (state = {}, action) => {
    switch (action.type){
        case ORDER_DELIVER_REQUEST:
              //return {loading: true}
              return state
        case ORDER_DELIVER_SUCCESS:
            return {loading: false, success: true}
        case ORDER_DELIVER_FAIL:
            return {loading: false, error: action.payload}
        case ORDER_DELIVER_RESET:
            return {}
        default:
            return state
    }
}