import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { listProducts } from '../actions/productActions'
import { detailsUser } from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import Product from '../components/Product'
import ProductPagination from '../components/ProductPagination'
import Rating from '../components/Rating'
import dompurify from 'dompurify'
import { get_user } from '../utils'


export default function SellerScreen(props) {

    //const { sellerId } = useParams()
    //const userInfo = props.userinfo

    const sellerId  = useParams().sellerId || props.sellerId
    
    
   
    // const userDetails = useSelector(state => state.userDetails)
    // const {loading, error, user} = userDetails

    const [banner_style, set_banner_style] = useState(null)
    const [description_style, set_description_style] = useState(null)
    const [products_style, set_products_style] = useState(null)
    const [name_style, set_name_style] = useState(null)
    const [subtitle_style, set_subtitle_style] = useState(null)
    const [footer_style, set_footer_style] = useState(null)
    const dispatch = useDispatch()
  
    const [user, set_user] = useState(null)
    const [loading, set_loading] = useState(null)
    const [error, set_error] = useState(null)

    const param_seller_id = useParams().sellerId

    function on_other_users_domain(user){
        return window.location.hostname !== 'localhost' && window.location.hostname !== 'lundazon.se' && window.location.hostname !== user.seller.domain
    }

    useEffect(() => {
        if (user) {
            if (param_seller_id && on_other_users_domain(user)) {
                window.location.assign('https://lundazon.se/seller/'+param_seller_id)
            }
            else {
                if (user.seller.domain === window.location.hostname) document.title = user.seller.name
                if (user.seller.banner_background_image) {
                    //set_banner_style( {background: `url(${user.seller.banner_background_image}) center center`})
                    set_banner_style( {"background-image": `url(${user.seller.banner_background_image})`, "background-repeat": "none",  "background-size": "cover" })
                }
                else set_banner_style({ background: `none`})
                set_description_style( {backgroundColor: `${user.seller.description_background_color}`, color: user.seller.description_text_color })
                set_products_style( {backgroundColor: `${user.seller.products_background_color}`})
                set_name_style( {color: `${user.seller.name_text_color}`})
                set_subtitle_style( {color: `${user.seller.subtitle_text_color}`})
                set_footer_style({backgroundColor: `${user.seller.footer_background_color}`, color: user.seller.footer_text_color })
            }
        }
        
    }, [param_seller_id, props, user])

    useEffect(() => {
        if (sellerId && !user){ // && !user
           // dispatch(detailsUser(sellerId))
            get_user({_id: sellerId}, set_user, set_error, set_loading)
        }
    }, [sellerId, user]) // ta bort dispatch här

    function shop_card(){
        return (
            <div className="row start">
                <div className="p-1">
                    <img className="small" src={user.seller.logo} alt={user.seller.name}></img>
                </div>
                <div className="p-1">
                    <h1>
                        {user.seller.name}
                    </h1>
                </div>
                <div className="p-1 shop_subtitle">
                    <h1>
                        {user.seller.subtitle}
                    </h1>
                </div>
            </div>
        )
    }

    const [pageNumber, setPageNumber] = useState(1)
    const productList = useSelector(state => state.productList)
    const {loading: loadingProducts, error: errorProducts, products, page, pages} = productList
    console.log("pageNumber: "+pageNumber)
    //const myRef = useRef(null)

    useEffect(() => {
        if (sellerId) dispatch(listProducts({seller: sellerId, pageNumber: pageNumber}))
    }, [dispatch, sellerId, pageNumber])

    function product_pagination(){
        return (
    <div >
            {loadingProducts ? <LoadingBox></LoadingBox>
            : errorProducts ? <MessageBox variant="danger">{errorProducts}</MessageBox>
                : products && (
                    <>
                        {products.length === 0 && (<MessageBox>No Product Found</MessageBox>)}
                        <div className="row center">
                            {products.map((product) => (
                                <Product key={product._id} product={product}></Product>
                            ))}
                        </div>
                        <div className="row center pagination" >
                        {   
                            [...Array(pages).keys()].map(x => (
                                <button className={x + 1 === page ? 'active' : ''} key={x + 1} onClick={() => setPageNumber(x+1)}>{x + 1}</button>
                            ))
                        }
                        </div>
                    </>
                )}
        </div>
        )
    }


	return (
        <>
        
         {
                    // loading ? <LoadingBox></LoadingBox>
                    // : error ? <MessageBox variant="danger">{error}</MessageBox>
                    //     : 
                        ((products  || errorProducts) && sellerId && user && banner_style && description_style && products_style && name_style && subtitle_style && footer_style) ? (

                            <div>

                              
                                    <div className="banner" style={banner_style}>

                                        <div className={user.seller.subtitle ? "shop-title" : "shop-title-no-subtitle"}>
                                            <div className="row center">
                                                {user.seller.has_logo &&
                                                        <div className="p-1">
                                                            <img className="small" src={user.seller.logo} alt={user.seller.name}></img>
                                                        </div>
                                                }
                                                <div className="p-1">
                                                    <div>
                                                        <h1 className="seller-name-header" style={name_style}>
                                                            {user.seller.name}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                            {user.seller.subtitle &&
                                                <div className="row center shop_subtitle" style={subtitle_style}>
                                                    <h1 class="seller-subtitle">
                                                        {user.seller.subtitle}
                                                    </h1>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                

                                <div className="row top center" style={description_style}>
                                   
                                        <div  className="seller-description" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(user.seller.description) }} />
                                   
                                </div>
                                
                                <div className="row top" style={products_style}>
                                    <div className="col-3">
                                        {product_pagination()}
                                        {/* <ProductPagination sellerId={sellerId}></ProductPagination> */}
                                    </div>
                                </div>

                                <div className="row top p-3" style={footer_style}>
                                   <div className="row shop_footer"  style={footer_style}>
                                           
                                        <div className="p-2">
                                        Address: {user.name}, {user.seller.address}, {user.seller.post_number}, {user.seller.city}
                                        </div>
                                        <div  className="p-2">
                                            Email: {user.email} 
                                        </div>
                                        <div  className="p-2">
                                            Phone: {user.seller.phone} 
                                        </div>
                                        <div   className="p-2">
                                            Org.nbr: {user.seller.organisation_number}
                                        
                                        </div>
                                    </div>
                                </div>
                                      

                            
                                
                            </div>
                        )
                        : <></>
}
        </>
	)

}



