import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { get_user} from '../utils.js'

export default function SellerApplicationScreen(props) {
   
    const [seller_plan, set_seller_plan] = useState("")
    const [seller_name, set_seller_name] = useState("")
    const [seller_description, set_seller_description] = useState("")
    const [seller_address, set_seller_address] = useState("")
    const [seller_post_number, set_seller_post_number] = useState("")
    const [seller_city, set_seller_city] = useState("")
    const [seller_phone, set_seller_phone] = useState("")
    const [seller_organisation_number, set_seller_organisation_number] = useState("")
    const [error, set_error] = useState(null)
    const [loading, set_loading] = useState(null)
    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin
    const [user, set_user] = useState(null)

    useEffect(() => {
        get_user(userInfo, set_user, set_error, set_loading)  
    }, [userInfo])

    useEffect(() => {
        if (user) {
            set_seller_name(user.seller.name)
            set_seller_description(user.seller.description)
            set_seller_address(user.seller.address)
            set_seller_post_number(user.seller.post_number)
            set_seller_city(user.seller.city)
            set_seller_phone(user.seller.phone)
            set_seller_organisation_number(user.seller.organisation_number)
            set_seller_plan(user.seller.plan)
        }
    }, [user])


	return (
		<div className="settings">
		 
           
            {
                loading ? <LoadingBox></LoadingBox>
                : error ? <MessageBox variant="danger">{error}</MessageBox>
                : user &&
                    <div className="form">
                        <h1 className="p-2 theme-header">Shop Application</h1>
                        <div className="form-card p-1">
                            <h3>Plan</h3> {seller_plan}
                        </div>
                        <div className="form-card p-1">
                            <h3>Status</h3>   
                            {user.is_seller ? <div className="shop-open">Open</div> : 
                            user.wants_to_be_seller ? <div className="pending">Waiting for approval</div>  : 
                            <div className="danger">Closed</div>}
                        </div>
                        <div  className="form-card p-1">
                            <h3>Name</h3>
                            {seller_name}
                        </div>
                        <div  className="form-card p-1">
                            <h3>Address</h3>
                                {seller_address}
                         </div>
                        <div  className="form-card p-1">
                            <h3>Post number</h3>
                            {seller_post_number}
                        </div>
                        <div  className="form-card p-1">
                            <h3>City</h3>
                            {seller_city}
                        </div>
                       
                        <div  className="form-card p-1">
                            <h3>Phone</h3>
                            {seller_phone}
                        </div>
                        <div  className="form-card p-1">
                            <h3>Organisation Number</h3>
                            {seller_organisation_number}
                        </div>
                        <div  className="form-card p-1">
                            <h3>Description</h3>
                            {seller_description}
                        </div>
                    </div>
            }
    </div>
	)

}