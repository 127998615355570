
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {BrowserRouter, Link, Route} from 'react-router-dom'
import { signout } from './actions/userActions'
import HomeScreen from './screens//HomeScreen'
import CartScreen from './screens/CartScreen'
import ProductScreen from './screens/ProductScreen'
import SigninScreen from './screens/SigninScreen'
import RegisterScreen from './screens/RegisterScreen'
import ShippingAddressScreen from './screens/ShippingAddressScreen'
import PaymentMethodScreen from './screens/PaymentMethodScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import OrderHistoryScreen from './screens/OrderHistoryScreen'
import ProfileScreen from './screens/ProfileScreen'
import PrivateRoute from './components/PrivateRoute'
import ProductListScreen from './screens/ProductListScreen'
import AdminRoute from './components/AdminRoute'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import SellerRoute from './components/SellerRoute'
import SellerScreen from './screens/SellerScreen'
import MyShopMenuScreen from './screens/MyShopMenuScreen'
import AdminMenuScreen from './screens/AdminMenuScreen'
import SearchBox from './components/SearchBox'
import SearchScreen from './screens/SearchScreen'
import { listProductCategories } from './actions/productActions'
import LoadingBox from './components/LoadingBox'
import MessageBox from './components/MessageBox'
import MapScreen from './screens/MapScreen'
import BecomeSellerScreen from './screens/BecomeSellerScreen'
import SellerInfoScreenI from './screens/SellerInfoScreenI'
import AboutScreen from './screens/AboutScreen'
import SellerProfileScreen from './screens/SellerProfileScreen'
import SellerApplicationScreen from './screens/SellerApplicationScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen'
import SellerInfoScreenII from './screens/SellerInfoScreenII'
import PrivacyScreen from './screens/PrivacyScreen'
import { get_user } from './utils'
import CategoriesScreen from './screens/CategoriesScreen'
import AdminSettingsScreen from './screens/AdminSettingsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'

function App(props) {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const [sidebar2IsOpen, setSidebar2IsOpen] = useState(false)
  const cart = useSelector(state => state.cart)
  const {cartItems} = cart
  const userSignin = useSelector((state) => state.userSignin)
  const {userInfo} = userSignin 
  const dispatch = useDispatch()



 
  const signoutHandler = () => {
    console.log("signout handler")
    dispatch(signout())
    setSidebarIsOpen(false)
    setSidebar2IsOpen(false)
  }
  const productCategoryList = useSelector(state => state.productCategoryList)
  const {loading: loadingCategories, error: errorCategories, categories } = productCategoryList

  const [test, set_test] = useState({Hej: "Hej"})

  useEffect(() => {
    console.log("App.js: test: "+JSON.stringify(test))
  }, [test])

  const [user, set_user] = useState(null)
  const [loading_user_error, set_loading_user_error] = useState(null)
  const [loading_user, set_loading_user] = useState(null)

  useEffect(() => {
    get_user(userInfo, set_user, set_loading_user_error, set_loading_user)
  }, [userInfo])

  useEffect(() => {
    if (user){
      console.log("App.js: User object: "+JSON.stringify(user))
    }
  }, [user])

  console.log("const pathname: "+ window.location.pathname.substring(0, 8))
  

  useEffect(() => {
    dispatch(listProductCategories())
  }, [dispatch])

  function open_sidebar(){
    setSidebarIsOpen(true)
    setSidebar2IsOpen(false)
  }
  
  function open_sidebar2(){
    setSidebar2IsOpen(true)
    setSidebarIsOpen(false)
  }

  function hamburger_menu_button(menu){
    return (
        <button type="button" className="open-sidebar" onClick={() => open_sidebar()}>
          <i className="fa fa-bars"> </i>
        </button>
    )
  }

  function hamburger_right_menu_button(menu){
    return (
        <button type="button" className="open-sidebar hamburger-right" onClick={() => open_sidebar2()}>
          <i className="fa fa-bars"> </i>
        </button>
    )
  }

  function brand(){
    return (
      <>
      <a  className="brand" href="https://lundazon.se">Lundazon</a>
      {/* <Link className="brand" to="/"> Lundazon </Link>
      <img class="small beta-tag" src="/images/beta-tag.png" alt="beta-tag"></img>
      {window.location.hostname} */}
      </>
    )
  }

  function info_menu(){
    return (
      <div className="dropdown">
        <Link to ="#">Info <i className="fa fa-caret-down"> </i></Link>
        <ul className="dropdown-content right">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link className={(userInfo && userInfo.is_seller) ? "greyed-out-link" : ""} to="/seller_info_1">Become Seller</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div>
    )
  }

  function cart_button(){
    return (
      <div className="broad_screen">
      <Link to="/cart" onClick={() => setSidebar2IsOpen(false)}>Cart 
            {cartItems.length > 0 && (
              <span className="badge">{cartItems.length}</span>
            )}
      </Link>
      </div>
    )
  }

  function user_dropdown(){
    return (
      <div className="dropdown broad_screen">
        <Link to ="#">{userInfo.name} <i className="fa fa-caret-down"> </i></Link>
        <ul className="dropdown-content left">
          <li>
            <Link to="/profile">User Profile</Link>
          </li>
          <li>
            <Link to="/orderhistory">Order History</Link>
          </li>
          <li>
            {signout_button()}
          </li>
        </ul>
      </div>
    )
  }

  function signin_button(){
    return (
      <Link to="/signin">Sign In</Link>
    )
  }

  function signout_button(){
    return (
      <Link to="#signout" onClick={signoutHandler}>Sign out</Link>
    )
  }

  function on_default_domain(){
    return (window.location.hostname === 'lundazon.se' || window.location.hostname === 'localhost')
  }

  function on_own_custom_domain(){
    return (user && user.seller && window.location.hostname === user.seller.domain)
  }

  function admin_dropdown(){
    return (
      <div className="dropdown broad_screen">
        {display_admin_links() &&
          <>
          <Link to="#admin">Admin {' '} 
            <i className="fa fa-caret-down"></i>
          </Link>
          <ul className="dropdown-content left">
            {admin_links("")}
          </ul>
          </>
        }
      </div>
    )
  }

  function seller_dropdown(){
    return (
        <div className="dropdown broad_screen">
            {display_seller_links() ?
            <>
              <Link to="#admin">My Shop {' '} 
                <i className="fa fa-caret-down"></i>
              </Link>
              <ul className="dropdown-content left">
                {seller_links()}
              </ul>
            </>
            : become_seller_link()
        }
        </div>
    )
  }

  function seller_links(prefix){
    return (
      <>
    <li> <Link to="/productlist/seller">{prefix} Products</Link> </li>
    <li> <Link to="/orderlist/seller">{prefix} Orders</Link> </li>
    <li> <Link to={`/seller/${userInfo._id}`}>{prefix} View Shop</Link> </li>
    <li> <Link to={`/seller_profile`}>{prefix} Settings</Link> </li>
    </>
    )
  }

  function admin_links(prefix){
    return (
      <>
        <li> <Link to="/productlist">{prefix} Products</Link> </li>
        <li> <Link to="/orderlist">{prefix} Orders</Link> </li>
        <li> <Link to="/userlist">{prefix} Users</Link> </li>
        <li> <Link to="/admin_settings">{prefix} Settings</Link> </li>
      </>
    )
  }
  function display_admin_links(){
    return userInfo && userInfo.is_admin && on_default_domain()
  }

  function display_seller_links(){
    return userInfo && userInfo.is_seller && (on_default_domain() || on_own_custom_domain())
  }

  function become_seller_link(){
    return (on_default_domain()) && <Link to="/seller_info_1">Become Seller</Link>
  }

  function categories_list(){
    return (
      <>
      <ul className="categories">
      <li>
              <strong>Categories</strong>
              <button
                onClick={() => setSidebarIsOpen(false)}
                className="close-sidebar"
                type="button"
              >
                <i className="fa fa-close"></i>
              </button>
            </li>
      {
        loadingCategories ? ( <LoadingBox> </LoadingBox> ) : 
        errorCategories ? (<MessageBox variant="danger">{errorCategories}</MessageBox>) :
        (
          categories.map((c) => (
            <li key={c}>
              <Link to={`/search/category/${c}`}
              onClick={() => setSidebarIsOpen(false)}>{c}</Link>
            </li>
          ))
        )
      }
      </ul>
      </>
    )
  }

  function hamburger_right_menu(){
    return(
      <ul className="categories">
            <li>
              <strong>Menu</strong>
              <button
                onClick={() => setSidebar2IsOpen(false)}
                className="close-sidebar"
                type="button"
              >
                <i className="fa fa-close"></i>
              </button>
            </li>
            {display_admin_links() && <li> <Link to="/admin_menu" onClick={() => setSidebar2IsOpen(false)}>Admin</Link> </li>  }
            {display_seller_links() && <li> <Link to="/my_shop_menu" onClick={() => setSidebar2IsOpen(false)}>My Shop</Link> </li> }
          
            {userInfo && <li> <Link to="/profile" onClick={() => setSidebar2IsOpen(false)}>User Profile</Link> </li>}
           
            <li><Link to="/cart" onClick={() => setSidebar2IsOpen(false)}>Cart 
              {cartItems.length > 0 && (
                <span className="badge">{cartItems.length}</span>
              )}
            </Link>
            </li>
            <li> {userInfo && <Link to="/orderhistory" onClick={() => setSidebar2IsOpen(false)}>My outgoing orders</Link>}</li>
          
            <li> {userInfo && signout_button()} </li>
          </ul>
    )
  }

  return (
    <BrowserRouter>
      <div className="grid-container">
        <header className="row">
          {(window.location.pathname.substring(0, 8) !== '/seller') &&  
          (
            <>
              <div className="row mobile-header">
                {hamburger_menu_button()}
                {brand()}
                {info_menu()}
                <div className="small_screen">{(!userInfo) && signin_button() }</div>
                {hamburger_right_menu_button()}
              </div>
              <div className="broad_screen">
                <Route render={({ history }) => ( <SearchBox history={history}></SearchBox> )}></Route>
              </div>
              <div className="row p-1 slim-on-mobile">
                {cart_button()}
                {userInfo ? user_dropdown() : <div className="broad_screen">{signin_button()}</div>}
                {userInfo && seller_dropdown()}
                {userInfo && admin_dropdown()}
              </div>
            </>
          )
          }
        </header>

        <aside className={sidebarIsOpen ? 'open' : '' }>
          {categories_list()}
        </aside>

        <aside className={sidebar2IsOpen ? 'right-open' : 'right' }>
          {hamburger_right_menu()}
        </aside>

        <main>
          <Route exact path="/seller/:sellerId"> <SellerScreen userinfo={userInfo} set_test={set_test} test={test}/></Route>
          <Route exact path="/seller/:id/pageNumber/:pageNumber" component={SellerScreen}></Route>
          <Route path="/cart/:id?/:qty?" component = {CartScreen}></Route>
          <Route exact path="/product/:id" component={ProductScreen}></Route>
          <AdminRoute path="/product/:id/edit" component={ProductEditScreen} exact></AdminRoute>
          <SellerRoute path="/product/:id/edit/seller" component={ProductEditScreen} exact></SellerRoute>
          <Route path="/signin" component={SigninScreen}></Route>
          <Route path="/register" component={RegisterScreen}></Route>
          <Route path="/shipping" component={ShippingAddressScreen}></Route>
          <Route path="/payment" component={PaymentMethodScreen}></Route>
          <Route path="/placeorder" component={PlaceOrderScreen}></Route>
          <Route path="/order/:id" component={OrderScreen}></Route>
          <PrivateRoute path="/orderhistory" component={OrderHistoryScreen}></PrivateRoute>
          <PrivateRoute path="/profile" component={ProfileScreen}></PrivateRoute>
          <PrivateRoute path="/map" component={MapScreen}></PrivateRoute>
          <AdminRoute exact path="/productlist" component={ProductListScreen}></AdminRoute>
          <AdminRoute exact path="/productlist/pageNumber/:pageNumber" component={ProductListScreen}></AdminRoute>
          <AdminRoute exact path="/orderlist" component={OrderListScreen}></AdminRoute>
          <AdminRoute path="/userlist" component={UserListScreen}></AdminRoute>
          <AdminRoute path="/user/:id/edit" component={UserEditScreen}></AdminRoute>
          <AdminRoute path="/admin_menu" component={AdminMenuScreen}></AdminRoute>
          <AdminRoute path="/admin_settings" component={AdminSettingsScreen}></AdminRoute>
          <SellerRoute path="/my_shop_menu" component={MyShopMenuScreen}></SellerRoute>
          <SellerRoute exact path="/productlist/seller" component={ProductListScreen}></SellerRoute>
          <SellerRoute exact path="/productlist/seller/pageNumber/:pageNumber" component={ProductListScreen}></SellerRoute>
          <SellerRoute path="/orderlist/seller" component={OrderListScreen}></SellerRoute>
          <SellerRoute path="/seller_profile" component={SellerProfileScreen}></SellerRoute>
          <Route exact path="/" component={HomeScreen}></Route>
          <Route exact path="/search/name/:name?" component={SearchScreen}></Route>
          <Route exact path="/search/category/:category" component={SearchScreen}></Route>
          <Route exact path="/search/category/:category/name/:name" component={SearchScreen}></Route>
          <Route exact path="/categories" component={CategoriesScreen}></Route>
          <Route exact path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber" component={SearchScreen}></Route>
          <PrivateRoute exact path="/become_seller/:plan" component={BecomeSellerScreen}></PrivateRoute>
          <PrivateRoute exact path="/seller_application" component={SellerApplicationScreen} ></PrivateRoute>
          <Route exact path="/seller_info_1" component={SellerInfoScreenI}></Route>
          <Route exact path="/seller_info_2" component={SellerInfoScreenII}></Route>
          <Route exact path="/about" component={AboutScreen}></Route>
          <Route exact path="/forgot_password" component={ForgotPasswordScreen}></Route>
          <Route exact path="/reset_password/:token" component={ResetPasswordScreen}></Route> 
          <Route exact path="/privacy" component={PrivacyScreen}></Route> 
          <Route exact path="/privacy_policy" component={PrivacyPolicyScreen}></Route> 
        </main>
        <footer className="row center footer">
          <div>
            <a href="https://lundazon.se">Lundazon</a> Copyright © 2021 <a href="https://oddbits.se">OddBits AB</a> Traktorvägen 12, 226 60 Lund, Sweden.
            </div>
        </footer>
      </div>
    </BrowserRouter>
  )
}

export default App
