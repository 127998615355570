import React, { useEffect, useState } from 'react'
import dompurify from 'dompurify'
import { get_settings } from '../utils'
import { useSelector } from 'react-redux'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

export default function PrivacyPolicyScreen(props) {

    const [text, set_text] = useState(null)
    const [error, set_error] = useState(null)
    const [loading, set_loading] = useState(false)
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin

    function set_settings(data){
        set_text(data.privacy_policy_text)
    }

    useEffect(() => {
        get_settings(userInfo, set_settings, set_error, set_loading)
    }, [userInfo])

	return (
        <>
            {loading ? <LoadingBox></LoadingBox>
            : error ? <MessageBox variant="danger">{error}</MessageBox>
            : text &&
                <>
                    <div className="row center">
                        <div className="privacy-policy">
                            <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(text) }} />
                        </div>
                    </div>
                    <div className="row center">
                            <button className="about" onClick={() => {props.history.push(`/privacy`)}}>Tillbaka</button>
                           
                    </div>
                </>
            }
        </>
	)

}

