import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listProductCategories } from '../actions/productActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'


export default function CategoriesScreen(props) {
 

    console.log("ProfileScreen")

    const productCategoryList = useSelector(state => state.productCategoryList)
    const {loading: loadingCategories, error: errorCategories, categories } = productCategoryList
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listProductCategories())
      }, [dispatch])

   

    function categories_list(){
        return (
          <>
          {
            loadingCategories ? ( <LoadingBox> </LoadingBox> ) : 
            errorCategories ? (<MessageBox variant="danger">{errorCategories}</MessageBox>) :
            (
              categories.map((c) => (
                <li key={c}>
                  <Link to={`/search/category/${c}`}>{c}</Link>
                </li>
              ))
            )
          }
          </>
        )
      }

	return (
		<div>
		
            <div className="row center p-1">
                <h1>
                    Categories
                </h1>
            </div>
                
                
                <div>
                   <div  className="row center p-1">
                        {categories_list()}
                    </div>
                   
                </div>
           
        
    </div>
	)

}