import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {detailsUser, signin} from '../actions/userActions'
import {useDispatch, useSelector} from 'react-redux'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

export default function SigninScreen(props){
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const redirect = props.location.search ? props.location.search.split('=')[1]: '/'

    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo, loading, error} = userSignin 
    const dispatch = useDispatch()
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(signin(email, password))
        //TODO signin action
      
    }
    useEffect(() => {
        if (userInfo) {
            dispatch({type: USER_UPDATE_PROFILE_RESET})
            dispatch(detailsUser(userInfo._id))
            props.history.push(redirect)
        }
    }, [userInfo, props.history, redirect, dispatch])

    return (
        <div className="signin">
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>Sign In</h1>
                </div>
                {loading && <LoadingBox></LoadingBox>}
                {error && <MessageBox variant="danger">{error}</MessageBox>}
                <div>
                    <label htmlFor="email">Email address</label>
                    <input type="email" id="email" placeholder="Enter email" required onChange={(e) => setEmail(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" placeholder="Enter password" required onChange={(e) => setPassword(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label></label>
                    <button className="primary" type="submit">Sign in</button>
                </div>
                <div>
                    <label></label>
                    <div>
                        New customer? {' '}
                        <Link to={`/register?redirect=${redirect}`}>Create your account</Link>
                    </div>
                </div>
                <div>
                    <label></label>
                    <div>
                        Forgot password? {' '}
                        <Link to={`/forgot_password`}>Reset password</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}