
import React from 'react'
import { Link } from 'react-router-dom'
import Rating from './Rating'
export default function Product(props){
    const {product} = props
    return (
      
        <div key={product._id} className="card product-card">
            <Link to={`/product/${product._id}`}>
                <div className="product-image-container">
                <img className="product-image" src={product.image} alt={"Image for "+product.name} />
                </div>
            </Link>
            <div className="card-body">
                <Link to={`/product/${product._id}`}>
                    <h2>{product.name}</h2>
                </Link>
                <Rating rating={product.rating} numReviews={product.numReviews}></Rating>
                <div className="row">
                    <div className="price">SEK {product.price}&nbsp;&nbsp;</div>
                    <div className={product.countInStock > 0 ? "in_stock" : "not_in_stock"}>In stock: {product.countInStock}&nbsp;&nbsp;</div>
                    
                    {(product.seller && product.seller.seller) &&
                        <div> 
                            <Link to={`/seller/${product.seller._id}`}> {product.seller.seller.name} </Link>
                        </div>
                    }
                        
                </div>
            </div>
        </div>
    )
    
}


