
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { savePaymentMethod } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import { get_user } from '../utils'

export default function PaymentMethodScreen(props) {
	const cart = useSelector((state) => state.cart)
	const {shippingAddress, cartItems} = cart
	const [seller, set_seller] = useState(null)
	const userSignin = useSelector(state => state.userSignin)
	const {userInfo} = userSignin
	const [error, set_error] = useState(false)
	const [loading, set_loading] = useState(false)
	const [seller_has_paypal, set_seller_has_paypal] = useState(false)
	const [seller_has_swish, set_seller_has_swish] = useState(false)
	const [seller_swish_number, set_seller_swish_number] = useState("")
	const [payment_method, set_payment_method] = useState('')
	
	if (!shippingAddress.address){
		props.history.push('/shipping')
	}

	useEffect(() => {
		console.log("seller_request use effect cartItems: "+JSON.stringify(cartItems))
		if (userInfo && cartItems && cartItems.length > 0){
			const seller_request = {_id: cartItems[0].seller._id, token: userInfo.token}
			get_user(seller_request, set_seller, set_error, set_loading)
		}
	
	}, [cartItems, userInfo])

	useEffect(() => {
		if (seller){
			console.log("seller use effect")
			console.log("Seller: "+JSON.stringify(seller))
			set_seller_has_paypal(seller.seller.has_paypal)
			set_seller_has_swish(seller.seller.has_swish)
			set_seller_swish_number(seller.seller.swish_number)
			set_payment_method((seller.seller.has_swish && seller.seller.swish_number) ? 'Swish' : seller.seller.has_paypal ? 'PayPal' : '')
		}
	}, [seller])

	function payment_method_exists(){
		return (seller_has_paypal || (seller_has_swish && seller_swish_number))
	}

	const dispatch = useDispatch()

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(savePaymentMethod(payment_method))
		props.history.push('/placeorder')
	}

	console.log("PaymentMethodScreen")

	return (
		<div>
			<CheckoutSteps step1 step2 step3></CheckoutSteps>
			<form className="form" onSubmit={submitHandler}>
				<div>
					<h1>Payment Method</h1>
				</div>
				<div>
					{(!(cartItems && cartItems.length > 0)) &&
						<div> Cart is empty. </div>
					}
					{(!payment_method_exists()) &&
						<div>
							No payment methods available.
						</div>
					}
					{seller_has_paypal &&
						<div>
							<input type="radio" id="paypal" value="PayPal" name="paymentMethod"
							required checked={payment_method==='PayPal'} onChange={(e) => set_payment_method(e.target.value)}/> 
							<label htmlFor="paypal">PayPal</label>
						</div>
					}
					{(seller_has_swish && seller_swish_number) &&
						<div>
							<input type="radio" id="swish" value="Swish" name="paymentMethod"
							required checked={payment_method==='Swish'} onChange={(e) => set_payment_method(e.target.value)}/> 
							<label htmlFor="swish">Swish {seller_swish_number}</label>
						</div>
					}
				</div>
				{payment_method_exists() &&
					<div>
						<button className="primary" type="submit">Continue</button>
					</div>
				}
			</form>
		</div>
	)

}