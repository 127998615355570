import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createOrder } from '../actions/orderActions'
import CheckoutSteps from '../components/CheckoutSteps'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { ORDER_CREATE_RESET, ORDER_DELETE_RESET } from '../constants/orderConstants'

function left_column_content(cart){
    return (
        <ul>
            <li>
                <div className="card card-body">
                    <h2>Shipping</h2>
                    <p>
                        <strong>Name:</strong> {cart.shippingAddress.fullName} <br/>
                        <strong>Address:</strong> {cart.shippingAddress.address},
                        {cart.shippingAddress.city},
                        {cart.shippingAddress.postalCode},
                        {cart.shippingAddress.country}
                    </p>
                </div>
            </li>
            <li>
                <div className="card card-body">
                    <h2>Payment</h2>
                    <p>
                        <strong>Method:</strong> {cart.paymentMethod} 
                    </p>
                </div>
            </li>
            <li>
                <div className="card card-body">
                    <h2>Order Items</h2>
                    <ul>
                        {
                            cart.cartItems.map((item) => (
                                <li key={item.product}>
                                    <div className="row">
                                        <div>
                                            <img src={item.image} alt={item.name} className="small"></img>
                                        </div>
                                        <div className="min-30">
                                            <Link to={`/product/${item.product}`} > {item.name} </Link>
                                        </div>
                                        <div>
                                            {item.qty} x {item.price} = {item.qty * item.price}
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </li>
        </ul>
    )
}

function right_column_content(cart, dispatch, loading, error){
   
    const placeOrderHandler = () => {
        dispatch(createOrder({...cart, orderItems: cart.cartItems}))
    }

    return (
        <div className="card card-body">
            <ul>
                <li>
                    <h2>
                        Order Summary
                    </h2>
                </li>
                <li>
                    <div className="row">
                        <div>Items</div>
                        <div>SEK {cart.itemsPrice.toFixed(2)}</div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div>Shipping</div>
                        <div>SEK {cart.shippingPrice.toFixed(2)}</div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div>Tax</div>
                        <div>SEK {cart.taxPrice.toFixed(2)}</div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div><strong>Total</strong></div>
                        <div><strong>SEK {cart.totalPrice.toFixed(2)}</strong></div>
                    </div>
                </li>
                <li>
                    <button type="button" onClick={placeOrderHandler} className="primary block" disabled={cart.cartItems.length === 0}>Place Order</button>
                </li>
                {loading && <LoadingBox></LoadingBox>}
                {error && <MessageBox variant="danger"></MessageBox>}
            </ul>
        </div>
    )
}

export default function PlaceOrderScreen(props) {
    const cart = useSelector((state) => state.cart)
    const toPrice = (num) => Number(num.toFixed(2)) 
    const orderCreate = useSelector((state => state.orderCreate))
    const {loading, success, error, order} = orderCreate
    const dispatch = useDispatch()
    console.log("\nPlaceOrderScreen\n")
    if (!cart.paymentMethod){
        props.history.push('/payment')
    }
    cart.itemsPrice = toPrice(cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0))
    cart.shippingPrice = toPrice(cart.cartItems.reduce((a, c) => a + c.qty * c.shipping_price, 0))
    for(let x = 0; x< cart.cartItems.length; x++) console.log("cartItem "+x+": "+JSON.stringify(cart.cartItems[x]))
    console.log("\ncart: "+JSON.stringify(cart))
    //cart.shippingPrice = cart.itemsPrice > 100 ? toPrice(0) : toPrice(10)
    //cart.taxPrice = toPrice(0.15 * cart.itemsPrice)
    cart.taxPrice = 0
    cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice
    
    useEffect(() => {
        if (success) {
            props.history.push(`/order/${order._id}`)
            dispatch({type: ORDER_CREATE_RESET})
        }
    }, [dispatch, order, props.history, success])


	return (
		<div>
            <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
            <div className="row top">
                {cart.cartItems.length >0 ?
                    <>
                    <div className="col-2">
                        {left_column_content(cart)}
                    </div>
                    <div className="col-1">
                        {right_column_content(cart, dispatch, loading, error)}   
                    </div>    
                    </>
                    : <MessageBox variant="danger">Cart is empty.</MessageBox>
                }            
            </div>
		</div>
	)
}