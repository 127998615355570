
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { createProduct, deleteProduct, listProducts } from "../actions/productActions"
import LoadingBox from "../components/LoadingBox"
import MessageBox from "../components/MessageBox"
import { PRODUCT_CREATE_RESET, PRODUCT_DELETE_RESET } from "../constants/productConstants"
import { reformat_timestamp } from "../utils"

export default function ProductListScreen(props) {
    const {pageNumber = 1} = useParams()
    const sellerMode = props.match.path.indexOf('/seller') >= 0
    const productList = useSelector(state => state.productList)
    const {loading, error, products, page, pages} = productList
    const productCreate = useSelector(state => state.productCreate)
    const {loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct} = productCreate
    const dispatch = useDispatch()
    const productDelete = useSelector(state => state.productDelete)
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = productDelete
    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin
    
    useEffect(() => {
        console.log("seller mode: "+sellerMode)
    }, [sellerMode])

    useEffect(() => {
        console.log("useEffect pageNumber: "+pageNumber)
        if (successCreate) {
           // dispatch({type: PRODUCT_CREATE_RESET})
            if (sellerMode) props.history.push(`/product/${createdProduct._id}/edit/seller`)
            else props.history.push(`/product/${createdProduct._id}/edit`)
        }
        if (successDelete) {
            dispatch({type: PRODUCT_DELETE_RESET})
        }
        dispatch(listProducts({ seller: sellerMode ? userInfo._id : '', pageNumber, pageSize: 50}))
    }, [pageNumber, createdProduct, dispatch, props.history, sellerMode, successCreate, successDelete, userInfo._id])

    
    const deleteHandler = (product) => {
        if (window.confirm('Are you sure to delete?')){
            dispatch(deleteProduct(product._id))
        }
    }

    const createHandler = () => {
        dispatch(createProduct())
       //props.history.push('/product/0/edit')
    }

    const create_button = () => {
        return (
            sellerMode &&
            <button type="button" className="primary" onClick={createHandler}>
                Create Product
            </button>
        )
    }

    function go_to_edit_screen(product_id){
        if (sellerMode) props.history.push(`/product/${product_id}/edit/seller`)
        else props.history.push(`/product/${product_id}/edit`)
    }

    const table = () => {
        return (
            <>
            <table className="table broad_screen">
                <thead>
                    <tr>
                        {!sellerMode && <th>ID </th>}
                        <th>NAME </th>
                        {!sellerMode && <th>SHOP</th>}
                        <th>IMAGE </th>
                        <th>PRICE </th>
                        <th>CATEGORY </th>
                        <th >BRAND </th>
                        <th >STOCK</th>
                        <th >CREATED</th>
                        <th >UPDATED</th>
                        <th>ACTIONS </th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product._id}>
                            {!sellerMode && <td >{product._id}</td>}
                            <td>{product.name}</td>
                            {!sellerMode && <td> {(product.seller && product.seller.seller) ? product.seller.seller.name : '<Unknown>'} </td> }
                            <td> <img className="small" src={product.image} alt={product.image}></img></td>
                            <td>{product.price}</td>
                            <td >{product.category}</td>
                            <td>{product.brand}</td>
                            <td >{product.countInStock}</td>
                            <td >{reformat_timestamp(product.createdAt)}</td>
                            <td>{reformat_timestamp(product.updatedAt)}</td>
                            <td> 
                                <button 
                                    type="button" 
                                    className="small list_action" 
                                    onClick={() => go_to_edit_screen(product._id)}
                                >
                                    Edit
                                </button>
                                <button 
                                    type="button" 
                                    className="small list_action" 
                                    onClick={() => deleteHandler(product)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="small_screen">
                {products.map((product) => (
                    <div key={product._id} className="small_screen_card">
                        <table className="small_screen_table">
                            {!sellerMode && <tr><td><b>ID</b></td><td>{product._id}</td></tr>}
                            <tr><td><b>Name</b></td><td>{product.name}</td></tr>
                            {!sellerMode && <tr><td><b>Shop</b></td><td> {(product.seller && product.seller.seller) ? product.seller.seller.name : '<Unknown>'}</td></tr>}
                            <tr><td><b>Image</b></td><td> <img className="small" src={product.image} alt={product.image}></img></td></tr>
                            <tr><td><b>Price</b></td><td >{product.price}</td></tr>
                            <tr><td><b>Category</b></td><td >{product.category}</td></tr>
                            <tr><td><b>Brand</b></td><td>{product.brand}</td></tr>
                            <tr><td><b>Stock</b></td><td>{product.countInStock}</td></tr>
                            <tr><td><b>Created</b></td><td >{reformat_timestamp(product.createdAt)}</td></tr>
                            <tr><td><b>Updated</b></td><td>{reformat_timestamp(product.updatedAt)}</td></tr>
                            <tr><td><b>Actions</b></td><td>
                                <button  type="button"  className="small list_action"  onClick={() => go_to_edit_screen(product._id)} > Edit </button>
                                <button type="button" className="small list_action" onClick={() => deleteHandler(product)}>Delete</button>
                            </td></tr>
                        </table>
                    </div>
                )) }
                    </div>
            { pagination() }
            </>
        )
    }

    const pagination = () => {
        return (
        <div className="row center pagination">
            {
                [...Array(pages).keys()].map(x => (
                    <Link className={x + 1 === page ? 'active' : ''} key={x + 1} to={`/productlist${sellerMode ? "/seller" : ""}/pageNumber/${x+1}`}>{x + 1}</Link>
                ))
            }
        </div>
        )
    }

	return (
		<div  className="p-1">
            <div className="row">
                {sellerMode ? <h1 className="theme-header">My shop / Products</h1> : <h1 className="theme-header">Admin / Products</h1>}
                {create_button()}
            </div>
            {loadingDelete && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
            {loadingCreate && <LoadingBox></LoadingBox>}
            {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
            {
                loading ? <LoadingBox></LoadingBox>
                : error ? <MessageBox variant="danger">{error}</MessageBox>
                : table() 
               

            }
         
		</div>
	)

}