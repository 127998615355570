import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import axios from '../../node_modules/axios/index'
import { detailsProduct, updateProduct } from '../actions/productActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { PRODUCT_UPDATE_RESET, PRODUCT_CREATE_RESET } from '../constants/productConstants'

export default function ProductEditScreen(props) {
    const productId = props.match.params.id
    const sellerMode = props.match.path.indexOf('/seller') >= 0
    const [name, set_name] = useState('')
    const [price, set_price] = useState(0)
    const [image, set_image] = useState('')
    const [category, set_category] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [brand, set_brand] = useState('')
    const [description, set_description] = useState('')
    const dispatch = useDispatch()
    const [new_product, set_new_product] = useState(false)
    const [shipping_price, set_shipping_price] = useState(0)
    const [shipping_info, set_shipping_info] = useState('')

    const product_details = useSelector(state => state.productDetails)
    const {loading, error, product} = product_details

    const product_update = useSelector(state => state.productUpdate)
    const {loading: loadingUpdate, error: errorUpdate, success: successUpdate} = product_update

    const submit_handler = (e) => {
        e.preventDefault()
        dispatch(updateProduct({_id: productId, name, price, image, category, brand, countInStock, description, shipping_price, shipping_info}))
       // TODO
    }

    const productCreate = useSelector(state => state.productCreate)
    const {success: successCreate} = productCreate

    console.log("ProductEditScreen")

    useEffect(() => {
        if (successCreate){
            console.log("ProductEditScreen: IV")
            set_new_product(true)
            dispatch({type: PRODUCT_CREATE_RESET})
        }
    }, [dispatch, successCreate])

    useEffect(() => {
        if (successUpdate) {
            console.log("ProductEditScreen: I")
            dispatch({type: PRODUCT_UPDATE_RESET})
            if (sellerMode) props.history.push('/productlist/seller')
            else props.history.push('/productlist')
        }
        if (!product || product._id !== productId || successUpdate) {
            console.log("ProductEditScreen: II")
            if (!product) console.log("!product")
            else if (product._id !== productId) {
                console.log("product._id !== productId")
            }
            else if (successUpdate) console.log("successUpdate")
            dispatch({type: PRODUCT_UPDATE_RESET})
            dispatch(detailsProduct(productId))
        }
        else {
            console.log("ProductEditScreen: III")
            set_name(product.name)
            set_price(product.price)
            set_image(product.image)
            set_category(product.category)
            setCountInStock(product.countInStock)
            set_brand(product.brand)
            set_description(product.description)
            set_shipping_price(product.shipping_price)
            set_shipping_info(product.shipping_info)
        }
    }, [product, dispatch, productId, successUpdate, props.history, sellerMode])
    
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [errorUpload, setErrorUpload] = useState('')
    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo } = userSignin

    const uploadFileHandler = async (e) => {
        console.log("uploadFileHandler")
        const file = e.target.files[0]
        const bodyFormData = new FormData()
        bodyFormData.append('image', file)
        setLoadingUpload(true)
        try {
            const { data } = await axios.post('/api/uploads', bodyFormData, {
                headers: { 
                    'Content-Type':'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                }
            } )
            set_image(data)
            setLoadingUpload(false)
        }
        catch (error){
            console.log("Upload error: "+JSON.stringify(error))
            if (error.message.indexOf("413") >= 0) setErrorUpload ("Too big image. Max size: 4 MB.")
            else setErrorUpload(error.message)
            setLoadingUpload(false)
        }
    }

    return (
		<div>
            <form className="form" onSubmit={submit_handler}>
                <div>
                    <h1 className="theme-header">{new_product ? "Create Product" : `Edit Product ${productId}`}</h1>
                </div>
                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
                {loading ? <LoadingBox></LoadingBox>
                : error ? <MessageBox variant="danger">{error}</MessageBox>
                :
                <>
                    <div>
                        <label htmlFor="name">Name</label>
                        <input id="name" type="text" placeholder="Enter name" value={name}
                        onChange={(e) => set_name(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="price">Price (SEK)</label>
                        <input id="price" type="text" placeholder="Enter price" value={price}
                        onChange={(e) => set_price(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="shipping_price">Shipping Price Per Item (SEK)</label>
                        <input id="shipping_price" type="text" placeholder="Enter shipping price" value={shipping_price}
                        onChange={(e) => set_shipping_price(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="info">Shipping Info</label>
                        <input id="shipping_info" type="text" placeholder="Enter shipping info" value={shipping_info}
                        onChange={(e) => set_shipping_info(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="image">Image</label>
                       <img className="medium" src={image} alt={product.image}></img>
                    </div>
                    <div>
                        <input id="imageFile" type="file" label="Choose image" 
                        onChange={uploadFileHandler}></input>
                        {loadingUpload && <LoadingBox></LoadingBox>}
                        {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                    </div>
                    <div>
                        <label htmlFor="category">Category</label>
                        <input id="category" type="text" placeholder="Enter category" value={category}
                        onChange={(e) => set_category(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="countInStock">Count In Stock</label>
                        <input id="countInStock" type="text" placeholder="Enter countInStock" value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="brand">Brand</label>
                        <input id="brand" type="text" placeholder="Enter brand" value={brand}
                        onChange={(e) => set_brand(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <textarea id="description" rows="3" type="text" placeholder="Enter description" value={description}
                        onChange={(e) => set_description(e.target.value)}></textarea>
                    </div>
                    <div>
                        <label>

                        </label>
                        <button className="primary" type="submit">{new_product ? "Create" : "Update"}</button>
                    </div>
                </>
            }
            </form>
		</div>
	)

}