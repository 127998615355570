
import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { listOrderMine } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

export default function OrderHistoryScreen(props) {
    const orderMineList = useSelector(state => state.orderMineList)
    const {loading, error, orders } = orderMineList
    const dispatch = useDispatch()
    console.log("OrderHistoryScreen")
    useEffect(() => {
        console.log("OrderHistoryScreen useeffect")
        dispatch(listOrderMine())
    }, [dispatch])
    if (orders && orders.length > 0) {
        console.log("OrderHistoryScreen orders: "+JSON.stringify(orders))
    }
	return (
		<div  className="p-1">
            <h1 className="theme-header">My outgoing orders</h1>
            {
                loading? <LoadingBox></LoadingBox> 
                : error? <MessageBox variant="danger">{error}</MessageBox>
                : 
                (
                    <table className="table">
                        <thead>
                            <tr>
                                <th  className="broad_screen">ID </th>
                                <th  className="broad_screen">DATE </th>
                                <th>SHOP</th>
                                <th>TOTAL </th>
                                <th>PAID </th>
                                <th>DELIVERED </th>
                                <th>ACTIONS </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order._id}>
                                    <td  className="broad_screen">{order._id}</td>
                                    <td  className="broad_screen">{order.createdAt.substring(0,10)}</td>
                                    <td>{order.seller.seller.name}</td>
                                    <td>{order.totalPrice.toFixed(2)}</td>
                                    <td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>
                                    <td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : 'No'} </td>
                                    <td>
                                        <button type="button" className="small" onClick={() => {props.history.push(`/order/${order._id}`)}}>
                                            Details
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            }
		</div>
    )
   

}