import React from 'react'


export default function AdminMenuScreen(props) {
 

    console.log("ProfileScreen")

    
    

    function go_to_admin_products(){
        props.history.push(`/productlist`)
    }

    function go_to_admin_orders(){
        props.history.push(`/orderlist`)
    }

    function go_to_admin_users(){
        props.history.push(`/userlist`)
    }

    function go_to_admin_settings(){
        props.history.push(`/admin_settings`)
    }

	return (
		<div>
		
            <div className="row center p-1">
                <h1>
                    Admin
                </h1>
            </div>
                
                
                <div>
                   <div  className="row center p-1">
                    <button className="primary menu" onClick={() => go_to_admin_products()}>Products</button>
                    </div>
                   <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_admin_orders()}>Orders</button></div> 
                    <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_admin_users()}>Users</button></div>
                    <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_admin_settings()}>Settings</button></div>
                </div>
           
        
    </div>
	)

}