
import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { deleteOrder, listOrders } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { ORDER_DELETE_RESET } from '../constants/orderConstants'
import { reformat_timestamp } from "../utils"

export default function OrderListScreen(props) {
    const sellerMode = props.match.path.indexOf('/seller') >= 0
    const orderList = useSelector(state => state.orderList)
    const {loading, error, orders } = orderList
    const dispatch = useDispatch()
    const orderDelete = useSelector(state => state.orderDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete} = orderDelete
    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin
    
    useEffect(() => {
        dispatch({type: ORDER_DELETE_RESET})
        dispatch(listOrders({ seller: sellerMode ? userInfo._id: ''}))
    },[dispatch, sellerMode, successDelete, userInfo._id])

    const deleteHandler = (order) => {
        if (window.confirm("Are you sure to delete?")){
            dispatch(deleteOrder(order._id))
        }
    }

    useEffect(() => {
        console.log("orders: "+JSON.stringify(orders))
    }, [orders])
    
    return (
        <div  className="p-1">
            {sellerMode ? <h1 className="theme-header">My Shop / Incoming Orders</h1> : <h1 className="theme-header">Admin / Orders</h1>}
            {loadingDelete && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{error}</MessageBox>}
            {
                loading? <LoadingBox></LoadingBox> 
                : error? <MessageBox variant="danger">{error}</MessageBox>
                : 
                (
                    <>
                    <table className="table broad_screen">
                        <thead>
                            <tr  >
                                <th>ID </th>
                                <th>CUSTOMER </th>
                                {!sellerMode && <th >SHOP</th>}
                                <th >DATE </th>
                                <th>TOTAL </th>
                                <th >PAID </th>
                                <th >DELIVERED </th>
                                
                                <th>ACTIONS </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order._id}>
                                    <td>{order._id}</td>
                                    
                                    <td >{order.user.name}</td>
                                    {!sellerMode && <td >{order.seller.seller.name}</td>}
                                    <td >{reformat_timestamp(order.createdAt)}</td>
                                    <td >{order.totalPrice.toFixed(2)}</td>
                                    <td >{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>
                                    <td >{order.isDelivered ? order.deliveredAt.substring(0, 10) : 'No'} </td>
                                    <td>
                                        <button type="button" className="small" onClick={() => {props.history.push(`/order/${order._id}`)}}>
                                            Details
                                        </button>
                                        <button type="button" className="small" onClick={() => deleteHandler(order)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="small_screen">
                        {orders.map((order) => (
                            <div key={order._id} className="small_screen_card">
                                <table className="small_screen_table">
                                    <tr><td><b>Id</b></td><td>{order._id}</td></tr>
                                    <tr><td><b>Customer</b></td><td>{order.user.name}</td></tr>
                                    {!sellerMode && <tr><td><b>Shop</b></td><td>{order.seller.seller.name}</td></tr>}
                                    <tr><td><b>Date</b></td><td>{reformat_timestamp(order.createdAt)}</td></tr>
                                    <tr><td><b>Total</b></td><td>{order.totalPrice.toFixed(2)}</td></tr>
                                    <tr><td><b>Paid</b></td><td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td></tr>
                                    <tr><td><b>Delivered</b></td><td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : 'No'}</td></tr>
                                    <tr><td><b>Actions</b></td><td>
                                        <button type="button" className="small list_action" onClick={() => {props.history.push(`/order/${order._id}`)}}>
                                            Details
                                        </button>
                                        <button type="button" className="small list_action" onClick={() => deleteHandler(order)}>Delete</button>
                                        </td></tr>
                                </table>
                            </div>
                        )) }
                    </div>
                    </>
                )
            }
        </div>
    )
}

