import React from 'react'
import { useSelector } from 'react-redux'


export default function MyShopMenuScreen(props) {
 

    console.log("MyShopMenuScreen")
    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin 


    function go_to_seller_products(){
        props.history.push(`/productlist/seller`)
    }

    function go_to_seller_orders(){
        props.history.push(`/orderlist/seller`)
    }

    function go_to_seller_profile(){
        props.history.push(`/seller_profile`)
    }

    function go_to_seller_shop(){
        props.history.push(`/seller/${userInfo._id}`)
    }

	return (
		<div>
		
        <div   className="row center p-1">
                <h1>
                    My shop
                </h1>
            </div>
                
                
                <div>
                   
                <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_seller_products()}>Products</button></div>
                <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_seller_orders()}>Orders</button></div>
                <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_seller_shop()}>View shop</button></div>
                <div   className="row center p-1"><button className="primary menu" onClick={() => go_to_seller_profile()}>Settings</button></div>

                </div>
               
            
        
        
        
    </div>
	)

}