import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteOrder, deliverOrder, detailsOrder, payOrder } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import {PayPalButton} from 'react-paypal-button-v2'
import { ORDER_DELETE_RESET, ORDER_DELIVER_RESET, ORDER_PAY_RESET } from '../constants/orderConstants'
import { update_order } from '../utils'

export default function OrderScreen(props) {
    const orderId = props.match.params.id
    const [sdkReady, setSdkReady] = useState(false)
    const orderDetails = useSelector(state => state.orderDetails)
    const {order, loading, error} = orderDetails
    const orderPay = useSelector(state => state.orderPay)
    const {loading: loadingPay, error: errorPay, success: successPay} = orderPay
    const dispatch = useDispatch()
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const orderDeliver = useSelector(state => state.orderDeliver)
    const {loading: loadingDeliver, error: errorDeliver, success: successDeliver} = orderDeliver
    const orderDelete = useSelector(state => state.orderDelete)
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = orderDelete
    const [is_paid, set_is_paid] = useState(false)
    const [is_delivered, set_is_delivered] = useState(false)
    const [paid_date, set_paid_date] = useState("")
    const [delivered_date, set_delivered_date] = useState("")

    const [update_message, set_update_message] = useState("")

    const deliverHandler = () => {
        dispatch(deliverOrder(order._id))
    }

    useEffect(() =>{
        console.log("Order: "+JSON.stringify(order))
        if (order){
            set_is_paid(order.isPaid)
            set_paid_date(date_part(order.paidAt))
            set_is_delivered(order.isDelivered)
            set_delivered_date(date_part(order.deliveredAt))
        }
    }, [order])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("OrderScreen")

    useEffect(() => {
        const addPayPalScript = async () => {
            const {data} = await axios.get('/api/config/paypal')
            const script = document.createElement('script')
            script.type = "text/javascript"
            script.src = `https://www.paypal.com/sdk/js?client-id=${data}`
            script.async = true
            script.onload = () => setSdkReady(true)
            document.body.appendChild(script)
        }
        if (!order || successPay || successDeliver || (order && order._id !== orderId) || successDelete) {
            dispatch({type: ORDER_PAY_RESET})
            dispatch({type: ORDER_DELIVER_RESET})
            dispatch({type: ORDER_DELETE_RESET})
            dispatch(detailsOrder(orderId))
        }
        else{
            if (!order.isPaid && order.paymentMethod === "PayPal"){
                if (!window.paypal) {
                    addPayPalScript()
                }
                else setSdkReady(true)
            }
        }
    }, [dispatch, order, orderId, sdkReady, successPay, successDeliver, successDelete])

    function handle_updated_order_success(data){
        set_update_message(data.message)
        dispatch(detailsOrder(orderId))
    }

    function handle_updated_order_error(data){
        set_update_message(data)
    }

    function date_part(date){
        if (date) return date.substring(0, 10)
    }
    const submit_handler = (e) => {
        e.preventDefault()
        if (is_paid && !paid_date) alert("Please enter the payment date.")
        else if (is_delivered && !delivered_date) alert("Please enter the delivery date.")
        else update_order(userInfo, order._id, {is_paid , paid_date, is_delivered, delivered_date}, handle_updated_order_success, handle_updated_order_error)
    }

    const delete_handler = (e) => {
        e.preventDefault()
        if (window.confirm("Are you sure to delete?")){
            dispatch(deleteOrder(order._id))
        }
    }



    function left_column_content(){
        return (
            <ul>
                <li>
                    <div className="card card-body">
                        <h2>Shipping</h2>
                        <p>
                            <strong>Name:</strong> {order.shippingAddress.fullName} 
                            </p><p>
                            <strong>Address:</strong> {order.shippingAddress.address}, 	   {' '}
                            {order.shippingAddress.city},    {' '}
                            {order.shippingAddress.postalCode}, 	   {' '}
                            {order.shippingAddress.country}
                        </p>
                        {order.isDelivered ? (<MessageBox variant="success">Delivered at {date_part(order.deliveredAt)}</MessageBox>) : 
                        (<MessageBox variant="danger">Not Delivered</MessageBox>)}
                    </div>
                </li>
                <li>
                    <div className="card card-body">
                        <h2>Payment</h2>
                        <p>
                            <strong>Method:</strong> {order.paymentMethod} 
                        </p>
                        <p>
                            <strong>Payment receiver:</strong> {order.seller.seller.name} 
                        </p>
                        {order.paymentMethod === 'Swish' && 
                        <p>
                            <strong>Swish number:</strong> {order.seller.seller.swish_number} 
                        </p>
                        
                        }
                        {order.isPaid ? (<MessageBox variant="success">Paid at {date_part(order.paidAt)}</MessageBox>) : 
                        (<MessageBox variant="danger">Not Paid</MessageBox>)}
                    </div>
                </li>
                <li>
                    <div className="card card-body">
                        <h2>Order Items</h2>
                        <ul>
                            {
                                order.orderItems.map((item) => (
                                    <li key={item.product}>
                                        <div className="row">
                                            <div>
                                                <img src={item.image} alt={item.name} className="small"></img>
                                            </div>
                                            <div className="min-30">
                                                <Link to={`/product/${item.product}`} > {item.name} </Link>
                                            </div>
                                            <div>
                                                {item.qty} x SEK {item.price} = SEK {item.qty * item.price}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </li>
            </ul>
        )
    }

    function right_column_content(){

        const successPaymentHandler = (paymentResult) => {
            dispatch(payOrder(order, paymentResult))
        }
    
        return (
            <div className="card card-body">
                <ul>
                    <li>
                        <h2>
                            Order Summary
                        </h2>
                    </li>
                    <li>
                        <div className="row">
                            <div>Items</div>
                            <div>SEK {order.itemsPrice.toFixed(2)}</div>
                        </div>
                    </li>
                    <li>
                        <div className="row">
                            <div>Shipping</div>
                            <div>SEK {order.shippingPrice.toFixed(2)}</div>
                        </div>
                    </li>
                    {/* <li>
                        <div className="row">
                            <div>Tax</div>
                            <div>SEK {order.taxPrice.toFixed(2)}</div>
                        </div>
                    </li> */}
                    <li>
                        <div className="row">
                            <div><strong>Order Total</strong></div>
                            <div><strong>SEK {order.totalPrice.toFixed(2)}</strong></div>
                        </div>
                    </li>
                    {
                        !order.isPaid && (
                            <li>
                                {order.paymentMethod === "PayPal" && 
                                    <>
                                    {!sdkReady ? (<LoadingBox></LoadingBox>) : (
                                        <>
                                        {errorPay && (
                                            <MessageBox variant="danger">{errorPay}</MessageBox>
                                        )}
                                        {loadingPay && <LoadingBox></LoadingBox>}
                                        <PayPalButton amount={order.totalPrice} onSuccess={successPaymentHandler}></PayPalButton>
                                        </>
                                    )
                                    }
                                    </>
                            }
                            </li>
                        )
                    }
                     <li>
                            
                            {(order.paymentMethod === 'Swish' && userInfo && userInfo._id === order.seller._id && !successDelete) &&
                                 <form className="form" onSubmit={submit_handler}>
                                  
                                   
                                    <div>
                                        <label><input id="is_delivered" type="checkbox"  checked={is_delivered}
                                        onChange={(e) => set_is_delivered(e.target.checked)}>
                                        </input> Is delivered
                                        </label> 
                                    </div>
                                    <div>
                                        <label htmlFor="delivered_date">Delivered date</label> <input id="delivered_date" type="date" value={delivered_date} placeholder="YYYY-MM-DD"
                                        onChange={(e) => set_delivered_date(e.target.value)} disabled={!is_delivered}>
                                        </input> 
                                    </div>

                                    <div>
                                        <label><input id="is_paid" type="checkbox" checked={is_paid} 
                                        onChange={(e) => set_is_paid(e.target.checked)}>
                                        </input> Is paid</label> 
                                    </div>
                                    <div>
                                        <label htmlFor="paid_date">Paid date</label> <input id="paid_date" type="date" value={paid_date} placeholder="YYYY-MM-DD"
                                        onChange={(e) => set_paid_date(e.target.value)} disabled={!is_paid}>
                                        </input> 
                                    </div>

                                    <div>
                                        <button className="primary" type="submit">Update</button>
                                    </div>  
                                    <div> {update_message}</div>
                                </form>
                            
                            }
                            {/* {`Logged in id: ${userInfo._id}`}
                            </li>
                             <li>
                            {`Order seller id: ${order.seller}`} */}
                            </li>
                    {order.paymentMethod === 'Swish' && userInfo && 
                    (userInfo.is_admin || userInfo._id === order.seller._id || userInfo._id === order.user) &&
                    !is_paid && !is_delivered && 
                        <li>
                            {!successDelete && 
                            <form className="form" onSubmit={delete_handler}>
                                <div>
                                    <button className="primary" type="submit">Delete order</button>
                                </div> 
                            </form>
                            }
                            <div>
                                {successDelete && <MessageBox  variant="success">Order was deleted.</MessageBox>}
                                {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
                            </div>
                        </li>
                     }

                    {/* {userInfo && userInfo.is_admin && order.isPaid && !order.isDelivered && (
                        <li>
                            {loadingDeliver && <LoadingBox></LoadingBox>}
                            {errorDeliver && (<MessageBox variant="danger">{errorDeliver}</MessageBox>)}
                            <button type="button" className="primary" onClick={deliverHandler}>Deliver Order</button>
                        </li>
                    )
    
                    } */}
                </ul>
            </div>
        )
    }

    return loading ? (<LoadingBox></LoadingBox>) : error ? (<MessageBox variant="danger">{error}</MessageBox>) :
    (
		<div className="p-1">
            <h1>Order {order._id}</h1>
            <div className="row top">
                <div className="col-2">
                    {left_column_content()}
                </div>
                <div className="col-1">
                    {right_column_content()}   
                </div>                
            </div>
		</div>
	)
}