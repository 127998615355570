

import React, { useEffect, useState } from 'react'
import dompurify from 'dompurify'
import { useSelector } from 'react-redux'
import { get_settings } from '../utils'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { Link } from 'react-router-dom'


export default function PrivacyScreen(props) {

    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const [error, set_error] = useState(false)
    const [loading, set_loading] = useState(false)
    const [text, set_text] = useState(null)

    function set_settings(data){
        set_text(data.privacy_text)
    }

    useEffect(() => {
        get_settings(userInfo, set_settings, set_error, set_loading)
    }, [userInfo])

	return (
        <>
            {loading ? <LoadingBox></LoadingBox>
            : error ? <MessageBox variant="danger">{error}</MessageBox>
            : text &&
                <>
                    <div className="row center">
                        <div className="seller-info">
                            <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(text) }} />
                            <Link to="/privacy_policy">Läs vår integritetspolicy</Link>
                        </div>
                        
                    </div>
                    <div className="row center">
                            <button className="about" onClick={() => {props.history.push(`/`)}}>Till startsida</button>
                        
                    </div>
                 </>
            }
        </>
	)

}



