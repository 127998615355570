
import React, {useState } from 'react'

import axios from '../../node_modules/axios/index'


export default function ForgotPasswordScreen(props){
    const [email, set_email] = useState('')
    const [status, set_status] = useState("")
   
    
    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            set_status("Sending email...")
            const {data} = await axios.post('/api/users/forgot_password', {email})
            console.log("email was sent data: "+JSON.stringify(data))
            set_status("Email was sent.")
        }
        catch(e){
            const message = e.response && e.response.data.message ? e.response.data.message : e.message
            set_status(message)
        }
    }
    

    return (
        <div className="forgot-password">
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>Send email with reset link</h1>
                </div>
          
                <div>
                    <label htmlFor="email">Email address</label>
                    <input type="email" id="email" placeholder="Enter email" required onChange={(e) => set_email(e.target.value)}>
                    </input>
                </div>
               
                <div>
                    <label></label>
                    <button className="primary" type="submit">Submit</button>
                </div>
                <div>
                    {status}
                </div>
            </form>
        </div>

    )
}