import React, { useEffect, useState } from 'react'
import Product from '../components/Product'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../actions/productActions'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { listTopSellers } from '../actions/userActions'
import { Link } from 'react-router-dom'
import SellerScreen from './SellerScreen'
import {get_domain_seller} from '../utils.js'

export default function HomeScreen(props){
  const dispatch = useDispatch()
  const productList= useSelector (state => state.productList)
  const {loading, error, products} = productList
  const userTopSellersList= useSelector (state => state.userTopSellerList)
  const {loading: loadingSellers, errorSellers, users: sellers} = userTopSellersList
  console.log("HomeScreen")
  const userSignin = useSelector(state => state.userSignin)
  const {userInfo} = userSignin
  const [domain_seller, set_domain_seller] = useState('0') 

  useEffect(() => {
    dispatch(listProducts({}))
    dispatch(listTopSellers())
  }, [dispatch])

  useEffect(() => {
    
    get_domain_seller(window.location.hostname, set_domain_seller)

  }, [domain_seller])

  useEffect(() => {
    
    console.log("domain_seller: "+domain_seller)
  }, [domain_seller])


  // useEffect(() => {
  //   console.log("host: "+window.location.hostname)
  //   if (props.history) {
    
      
  //     if ( window.location.hostname === 'arvidsshop.lundazon.se'){
  //       props.history.push(`/seller/6037a1eed961b2e95d08ba65`)
  //     }
      
  //      if ( window.location.hostname === 'localhost') {
  //       props.history.push(`/seller/6038eaae7585556991d02dec`)
       
  //     }
  //  }
  //  else console.log("There is no history object.")
  // }, [props.history])

  return (
    // window.location.hostname === 'localhost' ? <SellerScreen sellerId="6038eaae7585556991d02dec"></SellerScreen> :
    // window.location.hostname === 'arvidsshop.lundazon.se' ? <SellerScreen sellerId="6037a1eed961b2e95d08ba65"></SellerScreen> :
    // window.location.hostname === 'tellusaart.se' ? <SellerScreen sellerId="6048dbf6e7464f6f6511b6ca"></SellerScreen> :
  
  
    domain_seller === '0' ? <></> : (
  
    (window.location.hostname === 'lundazon.se' || window.location.hostname === 'localhost') ?

  <div className="p-1">
      <h2 className="theme-header">
        Top Sellers 
      </h2>
      {loadingSellers ? (<LoadingBox></LoadingBox>) : errorSellers ? (<MessageBox variant="danger">{errorSellers}</MessageBox>) : 
      (
        <>
        {sellers.length === 0 && <MessageBox> No Seller Found</MessageBox>}
        
        <div className="row center">
          {sellers.map((seller) => (
            <div key={seller._id} className="card top_seller">
              <Link to={`/seller/${seller._id}`}>

              {seller.seller.has_logo ?
                <div className="top_seller_container">
                  <div className="logo-container">
                    
                    <img className="top_seller_img" src={seller.seller.logo} alt={seller.seller.name} />
                    
                    
                  </div>

                  <div className="top_seller_name">{seller.seller.name}</div>
                </div>
                :
                <div className="no-logo-container"><div  className="top_seller_name">{seller.seller.name}</div></div>
              }
              </Link>

            </div>
             ))}
        </div>

        {/* <Carousel showArrows autoPlay showThumbs={true} style={{"background-image": "url('flygbild1.jpg')  center center;"}}>
          {sellers.map((seller) => (
            <div key={seller._id}>
              <Link to={`/seller/${seller._id}`}>
                <img src={seller.seller.logo} alt={seller.seller.name} />
                <p className="legend">{seller.seller.name}</p>
              </Link>

            </div>
          ))}

        </Carousel> */}
        </>

      )}

      {!userInfo &&
        <div  className="p-2 row center">
          <Link to="/register?redirect=/">Create an account</Link>&nbsp; to make your shopping easier
        </div>
      }
      <h2 className="theme-header">
        Featured Products
      </h2>
      {loading ? (<LoadingBox></LoadingBox>) : error ? (<MessageBox variant="danger">{error}</MessageBox>) : 
      (
        <>
          {products.length === 0 && <MessageBox> No Product Found</MessageBox>}
          <div className="row center">
            {products.map((product) => (
              <Product key={product._id} product={product}></Product>
            ))}
          </div>
        </>
      )}
  </div>

  : 
  <SellerScreen sellerId={domain_seller}></SellerScreen>
    )
  )
}