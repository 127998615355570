
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import {get_settings, get_user} from '../utils.js'
import dompurify from 'dompurify'

export default function SellerInfoScreenII(props) {
 
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const [user, set_user] = useState(null)
    const [user_error, set_user_error] = useState(false)
    const [user_loading, set_user_loading] = useState(false)
    const [text_error, set_text_error] = useState(false)
    const [text_loading, set_text_loading] = useState(false)
    const [text, set_text] = useState(null)
   
    console.log("SellerInfoScreen userInfo: "+JSON.stringify(userInfo))
    
    useEffect(() => {
        if (userInfo){
            get_user(userInfo, set_user, set_user_error, set_user_loading)
        }
        get_settings(userInfo, set_settings, set_text_error, set_text_loading)
    }, [userInfo])

    function set_settings(data){
        set_text(data.become_seller_text_2)
    }

    console.log("SellerInfoScreen: userInfo: "+JSON.stringify(userInfo))

	return (
		<div>
            {user_loading && <LoadingBox></LoadingBox>}
            {text_loading && <LoadingBox></LoadingBox>}
            {user_error && <MessageBox variant="danger">{user_error}</MessageBox>}
            {text_error && <MessageBox variant="danger">{text_error}</MessageBox>}
            {text &&
                <>
                    <div className="row center"> 
                        <div className="seller-info">
                            <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(text) }} />
                        </div>
                    </div>
                    <div className="row center">
                        <Link to={user ? `become_seller/1` : `signin?redirect=become_seller/1`}>
                        <div className="card card-body package-info package-info-active">
                            <h2>Plan 1 </h2>
                            <div>Max 5 produkter</div>
                            <div>Adress hos Lundazon</div>
                            <div>GRATIS</div>
                        </div>
                        </Link>
                        <Link to={user ? `become_seller/2` : `signin?redirect=become_seller/2`}>
                        <div className="card card-body package-info  package-info-active">
                            <h2>Plan 2 </h2>
                            <div>Max 50 produkter</div>
                            <div>Egen domän (frivilligt)</div>
                            <div>299 kr / år</div>
                        </div>
                        </Link>
                        {/* <Link to={user ? `become_seller/3` : `signin?redirect=become_seller/3`}> */}
                        <div className="card card-body package-info package-info-inactive">
                        <h2>Plan 3 </h2>
                        <div>(Ej valbar ännu)</div>
                            <div>Domän ingår</div>
                            <div>499 kr / år</div>
                        </div>
                        {/* </Link> */}
                    </div>
                    <div className="row center">
                        <button className="about" onClick={() => {props.history.push(`/`)}}>Till startsida</button>
                    </div> 
                </>
            }           
        </div>
	)

}