import React, { useState } from 'react'
import axios from '../../node_modules/axios/index'


export default function ResetPasswordScreen(props){

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const token = props.match.params.token
    const [status, set_status] = useState('')
  
    const submitHandler = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword){
            alert('Password and confirm password do not match')
        }
        else {
            try {
                set_status("Resetting password...")
                const {data} = await axios.post('/api/users/reset_password', {email, new_password: password, reset_password_token: token})
                set_status("Password was reset.")
                console.log(data)

            }
            catch(e){
                const message = e.response && e.response.data.message ? e.response.data.message : e.message
                set_status(message)
            }
        }
    }
  


    return (
        <div className="reset_password">
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>Reset your password</h1>
                </div>
        
                
                <div>
                    <label htmlFor="email">Email address</label>
                    <input type="email" id="email" placeholder="Enter email" required onChange={(e) => setEmail(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" placeholder="Enter password" required onChange={(e) => setPassword(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" placeholder="Confirm password" required onChange={(e) => setConfirmPassword(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label></label>
                    <button className="primary" type="submit">Submit</button>
                </div>
                <div>
                    {status}
                </div>
            </form>
        </div>
    )
}