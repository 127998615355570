import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, listUsers } from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_DETAILS_RESET } from '../constants/userConstants'
import { reformat_timestamp } from '../utils'


export default function UserListScreen(props) {
    const userList = useSelector(state => state.userList)
    const { loading, error, users } = userList

    const userDelete = useSelector(state => state.userDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = userDelete

    const dispatch = useDispatch()
    console.log("UserListScreen")
    console.log("users: "+JSON.stringify(users))

    useEffect(() => {
        dispatch(listUsers())
        dispatch({type: USER_DETAILS_RESET})
    }, [dispatch, successDelete])

    const deleteHandler = (user) => {
        if (window.confirm('Are you sure to delete?')){
            dispatch(deleteUser(user._id))
        }
    }

    function is_seller(user) {return user.is_seller ? 'YES' : 'NO'}
    function seller_status(user) {return user.is_seller ? 'YES' : user.wants_to_be_seller ? 'Wants to be' : 'NO'}
    function shop(user) {return (user.is_seller || user.wants_to_be_seller) ? user.seller.name: '-'}
    function joined(user) {return reformat_timestamp(user.createdAt)}
    function wants_to_be_seller(user) {return user.wants_to_be_seller ? 'YES' : 'NO'}
    function is_admin(user) {return user.is_admin ? 'YES' : 'NO'}

	return (
		<div  className="p-1">
            <h1 className="theme-header">Admin / Users </h1>
            {loadingDelete && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
            {successDelete && <MessageBox variant="success">User deleted successfully.</MessageBox>}
            {
                loading ?  <LoadingBox></LoadingBox>
                :
                error ? <MessageBox variant="danger">{error}</MessageBox>
                :
                <>
                <table className="table broad_screen">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>JOINED</th>
                            <th>IS SELLER</th>
                            <th>SHOP</th>
                            <th>WANTS TO BE SELLER</th>
                            <th>IS ADMIN</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user) => (
                                <tr key = {user._id}>
                                    <td>{user._id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{joined(user)}</td>
                                    <td>{is_seller(user)}</td>
                                    <td>{shop(user)} </td>
                                    <td>{wants_to_be_seller(user)}</td>
                                    <td>{is_admin(user)}</td>
                                    <td>
                                        <button type="button" className="small list_action" onClick={() => props.history.push(`/user/${user._id}/edit`)}>Edit</button>
                                        <button type="button" className="small list_action" onClick={() => deleteHandler(user)}>Delete</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                 <div className="small_screen">
                 {users.map((user) => (
                     <div key={user._id} className="small_screen_card">
                         <table className="small_screen_table">
                             <tr><td><b>Id</b></td><td>{user._id}</td></tr>
                             <tr><td><b>Name</b></td><td>{user.name}</td></tr>
                             <tr><td><b>Email</b></td><td >{user.email}</td></tr>
                             <tr><td><b>Joined</b></td><td >{joined(user)}</td></tr>
                             <tr><td><b>Seller</b></td><td >{is_seller(user)}</td></tr>
                             <tr><td><b>Shop</b></td><td>{shop(user)}</td></tr>
                             <tr><td><b>WTB Seller</b></td><td >{wants_to_be_seller(user)}</td></tr>
                             <tr><td><b>Admin</b></td><td >{is_admin(user)}</td></tr>
                             <tr><td><b>Actions</b></td><td>
                                 <button  type="button"  className="small list_action"  onClick={() => props.history.push(`/user/${user._id}/edit`)}>Edit</button>
                                 <button type="button" className="small list_action" onClick={() => deleteHandler(user)}>Delete</button>
                             </td></tr>
                         </table>
                     </div>
                 )) }
                     </div>
                     </>
            }
		</div>
	)

}