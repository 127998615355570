import React, { useEffect, useState } from 'react'
import {useSelector} from 'react-redux'
import axios from '../../node_modules/axios/index'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

export default function ProfileScreen(props) {
    const [name, set_name] = useState("")
    const [email, set_email] = useState("")
    const [password, set_password] = useState("")
    const [confirm_password, set_confirm_password] = useState("")
    const [seller_name, set_seller_name] = useState("")
    const [seller_logo, set_seller_logo] = useState("")
    const [seller_description, set_seller_description] = useState("")
    const [seller_subtitle, set_seller_subtitle] = useState("")
    const [is_seller, set_is_seller] = useState(false)
    const [wants_to_be_seller, set_wants_to_be_seller] = useState(false)
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const [loading, set_loading] = useState(false)
    const [error, set_error] = useState(null)
    const [loading_update, set_loading_update] = useState(false)
    const [error_update, set_error_update] = useState(null)
    const [success_update, set_success_update] = useState(false)
    const [user, set_user] = useState(null)
    const [id, set_id] = useState('')
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [errorUpload, setErrorUpload] = useState('')
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [showSnackbarMessage, setShowSnackbarMessage] = useState(false)

    console.log("ProfileScreen")

    
    
    useEffect(() => {
        async function get_user(userId){
            const loading_timeout = setTimeout(function(){ set_loading(true)}, 500);
            try {
                const {data} = await axios.get(`/api/users/${userId}`, {headers: { Authorization: `Bearer ${userInfo?.token}`}})
                set_user(data)
            }
            catch(e){
                set_error(e.response && e.response.data.message ? e.response.data.message : e.message)
            }
            clearTimeout(loading_timeout)
            set_loading(false)
        }
        get_user(userInfo._id)
    }, [userInfo._id, userInfo?.token])

    useEffect(() => {
       if(user){
            set_id(user._id)
            set_name(user.name)
            set_email(user.email)
            set_is_seller(user.is_seller)
            set_wants_to_be_seller(user.wants_to_be_seller)
            if (user.seller){
                set_seller_name(user.seller.name)
                set_seller_logo(user.seller.logo)
                set_seller_description(user.seller.description)
                set_seller_subtitle(user.seller.subtitle)
            }
        }
    }, [user])

    const submitHandler = (e) => {
        e.preventDefault()
        async function update_user_profile() {
            const loading_timeout = setTimeout(function(){ set_loading_update(true)}, 500);
            try {
                const updated_user = {
                    userId: id, 
                    name, 
                    email, 
                    password, 
                    seller_name, 
                    seller_logo, 
                    seller_description, 
                    seller_subtitle
                }
                const { data } = await axios.put(`/api/users/profile`, updated_user, {
                    headers: {Authorization: `Bearer ${userInfo.token}`}
                })
                localStorage.setItem('userInfo', JSON.stringify(data))
                set_success_update(true)
            }
            catch (error){
                set_error_update(e.response && e.response.data.message ? e.response.data.message : e.message)
            }
            clearTimeout(loading_timeout)
            set_loading(false)
        }
        if (password !== confirm_password){
            alert('Password and Confirm Password Are Not Matched')
        }
        else {
            update_user_profile()
        }
    }

    const uploadFileHandler = async (e, type) => {
        console.log("uploadFileHandler")
        const file = e.target.files[0]
        const bodyFormData = new FormData()
        bodyFormData.append('image', file)
        setLoadingUpload(true)
        try {
            const { data } = await axios.post('/api/uploads', bodyFormData, {
                headers: { 
                    'Content-Type':'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                }
            } )
            if (type === 'logo') set_seller_logo(data)
          
            setLoadingUpload(false)
        }
        catch (error){
            setErrorUpload(error.message)
            setLoadingUpload(false)
        }
    }

    const uploadLogoHandler = async (e) => {
        uploadFileHandler(e, 'logo')
    }

    useEffect(() => {
        console.log("useEffect successUpdate: "+success_update)
        if (success_update){
            setShowSnackbarMessage(true)
            setSnackbarMessage("Profile was updated.")
            setTimeout(function(){ setShowSnackbarMessage(false)}, 3000);
        }
    },[success_update])
    
    useEffect(() => {
        if (!showSnackbarMessage)  {set_success_update(false)}
    },[showSnackbarMessage])

    function apply(){
        props.history.push(`/seller_info`)
    }

	return (
		<div className="user-profile">
		 <form className="form" onSubmit={submitHandler}>
            <div>
                <h1 className="theme-header">
                    User Profile
                </h1>
            </div>
                {loading && <LoadingBox></LoadingBox>} 
                {error && <MessageBox variant="danger">{error}</MessageBox>}
                {loading_update && <LoadingBox></LoadingBox>}
                {error_update && (<MessageBox variant="danger">{error_update}</MessageBox>)}
                {success_update && <MessageBox variant="success">Profile Updated Successfully</MessageBox>}
                <div>
                    <label htmlFor="name" className="theme-label">Name</label>
                    <input id="name" 
                        type="text" 
                        placeholder="Enter name" 
                        value={name} 
                        onChange={(e) => set_name(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="email" className="theme-label">email</label>
                    <input id="email" 
                        type="email" 
                        placeholder="Enter email" 
                        value={email} 
                        onChange={(e) => set_email(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="password" className="theme-label">Password</label>
                    <input id="password" 
                        type="password" 
                        placeholder="Enter password" 
                        onChange={(e) => set_password(e.target.value)}>
                    </input>
                </div>
                <div>
                    <label htmlFor="confirmPassword" className="theme-label">Confirm Password</label>
                    <input id="confirmPassword" 
                        type="password" 
                        placeholder="Enter confirm password"
                        onChange={(e) => set_confirm_password(e.target.value)}>
                     </input>
                </div>
                
                <div>
                    <label/>
                    <button className="primary" type="submit">Update</button>
                </div>
               
            
        
        </form>
        <div className={showSnackbarMessage ? 'show' : ''}  id="snackbar">{snackbarMessage}</div>
        
    </div>
	)

}