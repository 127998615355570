import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import { detailsUser, updateUserProfile } from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import {get_plan_description} from '../utils.js'

export default function BecomeSellerScreen(props) {
    //const plan = props.location.search ? props.location.search.split('=')[1]: '/'
    const plan = useParams().plan
    const [plan_description, set_plan_description] = useState("")
    const [seller_name, set_seller_name] = useState("")
    const [seller_description, set_seller_description] = useState("")
    const [seller_address, set_seller_address] = useState("")
    const [seller_post_number, set_seller_post_number] = useState("")
    const [seller_city, set_seller_city] = useState("")
    const [seller_phone, set_seller_phone] = useState("")
    const [seller_organisation_number, set_seller_organisation_number] = useState("")
    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin
    const userDetails = useSelector((state) => state.userDetails)
    const {loading, error, user} = userDetails
    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { success: successUpdate, error: errorUpdate, loading: loadingUpdate} = userUpdateProfile
    const dispatch = useDispatch()
   
    
    useEffect(() => {
        set_plan_description(get_plan_description(plan))
    }
    ,[plan])

    useEffect(() => {
        dispatch({type: USER_UPDATE_PROFILE_RESET})
        dispatch(detailsUser(userInfo._id))
    }, [dispatch, userInfo._id])

    useEffect(() => {
        if (user){
            // if (user.is_seller) props.history.push('/seller_profile')
            // else if (user.wants_to_be_seller) props.history.push('/seller_application')
           
                set_seller_name(user.seller.name)
                set_seller_description(user.seller.description)
                set_seller_address(user.seller.address)
                set_seller_post_number(user.seller.post_number)
                set_seller_city(user.seller.city)
                set_seller_phone(user.seller.phone)
                set_seller_organisation_number(user.seller.organisation_number)
             
        }
    }, [props.history, user])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUserProfile({
            userId: user._id, 
            seller_name, 
            seller_description, 
            wants_to_be_seller: true,
            seller_address,
            seller_post_number,
            seller_city,
            seller_phone,
            seller_organisation_number
        }))
        props.history.push('/seller_application')
    }

  


	return (
		<div>
            {user && (
            
            
            (!user.is_seller && !user.wants_to_be_seller) ?

                <form className="form" onSubmit={submitHandler}>
            
                {
                    loading ? <LoadingBox></LoadingBox>
                    : error ? <MessageBox variant="danger">{error}</MessageBox>
                    :
                    <>
                    {loadingUpdate && <LoadingBox></LoadingBox>}
                    {errorUpdate && (<MessageBox variant="danger">{errorUpdate}</MessageBox>)}
                    {successUpdate && <MessageBox variant="success">Profile Updated Successfully</MessageBox>}
                    
                    
                        <h1>Create Shop</h1>
                        <div  className="card p-1">
                            <h3>Plan</h3> {plan}  ({plan_description})
                        </div>
                        <div  className="card p-1">
                            <h3>Shop Name</h3>
                            <input id="seller_name" type="text" placeholder="Enter Shop Name"
                            value={seller_name} onChange={(e) => set_seller_name(e.target.value)}>
                            </input>
                        </div>
                        <div className="card p-1">
                            <h3>Address</h3>
                            <label htmlFor="seller_address" className="form-label">Address</label>
                            <input id="seller_address" type="text" placeholder="Address" value={seller_address} onChange={(e) => set_seller_address(e.target.value)} required></input>
                            <label htmlFor="seller_post_number" className="form-label">Post number</label>
                            <input id="seller_post_number" type="text" placeholder="Postnr" value={seller_post_number} onChange={(e) => set_seller_post_number(e.target.value)} required></input>
                            <label htmlFor="seller_city" className="form-label">City</label>
                            <input id="seller_city" type="text" placeholder="City" value={seller_city} onChange={(e) => set_seller_city(e.target.value)}  required></input>
                            <label htmlFor="seller_phone" className="form-label">Phone</label>
                            <input id="seller_phone" type="text" placeholder="Phone" value={seller_phone} onChange={(e) => set_seller_phone(e.target.value)}  required></input>
                        </div>
                        <div className="card p-1">
                            <h3>Organisation number</h3>
                            <input id="seller_organisation_number" type="text" placeholder="NNNNNN-NNNN" value={seller_organisation_number} onChange={(e) => set_seller_organisation_number(e.target.value)}  required></input>
                        </div>
                        <div className="card p-1">
                            <h3>Shop Description</h3>
                            <textarea id="seller_description" rows="10" type="text" placeholder="Enter Shop Description"
                            value={seller_description} onChange={(e) => set_seller_description(e.target.value)}>
                            </textarea>
                        </div>
                        <div>
                            <label/>
                            <button className="primary" type="submit">Submit</button>
                        </div>
                    </>
                }
            
                </form>
                :
                (user.is_seller) ? (
                    <>
                    <div className="row center"> 
                        <div className="seller-info">
                            <p>Du är redan en säljare.</p>
                        </div>
                    </div>
                    <div className="row center">
                        <button className="about" onClick={() => {props.history.push(`/`)}}>Till startsida</button>
                        <button className="about" onClick={() => {props.history.push(`/seller_profile`)}}>Till webshop-inställningar</button>
                    </div>
                    </>
                )
                :
                (user.wants_to_be_seller) && (
                    <>
                    <div className="row center"> 
                        <div className="seller-info">
                            <p>You already applied to become a seller.</p>
                        </div>

                    </div>
                    <div className="row center">
                        <button className="about" onClick={() => {props.history.push(`/`)}}>Till startsida</button>
                        <button className="about" onClick={() => {props.history.push(`/seller_application`)}}>View application</button>
                    </div>
                    </>
                )
            )

            }
        
    </div>
	)

}