import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'

import axios from '../../node_modules/axios/index'
import { detailsUser, updateUserProfile } from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { get_user } from '../utils'
import Switch from "react-switch";

export default function SellerProfileScreen(props) {
  
    const [seller_name, set_seller_name] = useState("")
    const [seller_logo, set_seller_logo] = useState("")
    const [seller_has_logo, set_seller_has_logo] = useState(false)
    const [seller_description, set_seller_description] = useState("")
    const [seller_subtitle, set_seller_subtitle] = useState("")
    const [seller_banner_background_image, set_seller_banner_background_image] = useState("")
    const [seller_description_background_color, set_seller_description_background_color] = useState("")
    const [seller_products_background_color, set_seller_products_background_color] = useState("")
    const [seller_description_text_color, set_seller_description_text_color] = useState("")
    const [seller_address, set_seller_address] = useState("")
    const [seller_post_number, set_seller_post_number] = useState("")
    const [seller_city, set_seller_city] = useState("")
    const [seller_phone, set_seller_phone] = useState("")
    const [seller_organisation_number, set_seller_organisation_number] = useState("")
    const [seller_subtitle_text_color, set_seller_subtitle_text_color] = useState("")
    const [seller_name_text_color, set_seller_name_text_color] = useState("")
    const [seller_has_swish, set_seller_has_swish] = useState(null)
    const [seller_swish_number, set_seller_swish_number] = useState("")
    const [seller_footer_background_color, set_seller_footer_background_color] = useState("")
    const [seller_footer_text_color, set_seller_footer_text_color] = useState("")
    const [seller_domain, set_seller_domain] = useState("")
    const userSignin = useSelector(state => state.userSignin)
    const {userInfo} = userSignin
    const dispatch = useDispatch()
    
   
    // const userDetails = useSelector(state => state.userDetails)
    // const {loading, error, user} = userDetails

    const [loading, set_loading] = useState(false)
    const [user, set_user] = useState(null)
    const [error, set_error] = useState(false)

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success: successUpdate, error: errorUpdate, loading: loadingUpdate} = userUpdateProfile
   
  

    

   function update_seller(checked){
       console.log("update_seller checked: "+checked)
       set_seller_has_swish(checked)
      //console.log("update_seller seller_has_swish: "+seller_has_swish)
   }

   useEffect(() => {
    console.log("update_seller seller_has_swish: "+seller_has_swish)
   }, [seller_has_swish])

    useEffect(() => {
        get_user(userInfo, set_user, set_error, set_loading)
    }, [userInfo])

    useEffect(() => {
        if (user){
            console.log("SellerProfileScreen: User: "+JSON.stringify(user))
            set_seller_name(user.seller.name)
            set_seller_has_logo(user.seller.has_logo)
            set_seller_logo(user.seller.logo)
            set_seller_description(user.seller.description)
            set_seller_subtitle(user.seller.subtitle)
            set_seller_banner_background_image(user.seller.banner_background_image)
            set_seller_description_background_color(user.seller.description_background_color)
            set_seller_products_background_color(user.seller.products_background_color)
            set_seller_description_text_color(user.seller.description_text_color)
            set_seller_address(user.seller.address)
            set_seller_post_number(user.seller.post_number)
            set_seller_city(user.seller.city)
            set_seller_phone(user.seller.phone)
            set_seller_organisation_number(user.seller.organisation_number)
            set_seller_subtitle_text_color(user.seller.subtitle_text_color)
            set_seller_name_text_color(user.seller.name_text_color)
            set_seller_has_swish(user.seller.has_swish)
            set_seller_swish_number(user.seller.swish_number)
            set_seller_footer_background_color(user.seller.footer_background_color)
            set_seller_footer_text_color(user.seller.footer_text_color)
            set_seller_domain(user.seller.domain)
            }
    }, [user])

    const submitHandler = (e) => {
        e.preventDefault()
        console.log("submitHandler: seller_has_swish: "+seller_has_swish)
        if (seller_has_swish && !seller_swish_number)  alert("Please enter a swish number.")
        else if (seller_has_logo && seller_logo==="") alert("Please choose a logo image.")
        else {
            dispatch(updateUserProfile({
                userId: user._id, 
                seller_name, 
                seller_has_logo,
                seller_logo, 
                seller_description, 
                seller_subtitle, 
                seller_banner_background_image,
                seller_description_background_color,
                seller_products_background_color,
                seller_description_text_color,
                seller_address,
                seller_post_number,
                seller_city,
                seller_phone,
                seller_organisation_number,
                seller_subtitle_text_color,
                seller_name_text_color,
                seller_has_swish,
                seller_swish_number,
                seller_footer_background_color,
                seller_footer_text_color,
                seller_domain
            }))
        }
    }

    const [loadingUpload, setLoadingUpload] = useState(false)
    const [errorUpload, setErrorUpload] = useState('')

    const uploadFileHandler = async (e, type) => {
        console.log("uploadFileHandler")
        const file = e.target.files[0]
        const bodyFormData = new FormData()
        bodyFormData.append('image', file)
        setLoadingUpload(true)
        try {
            const { data } = await axios.post('/api/uploads', bodyFormData, {
                headers: { 
                    'Content-Type':'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                }
            } )
            if (type === 'logo') set_seller_logo(data)
            else if (type === 'banner_background_image') set_seller_banner_background_image(data)
            setLoadingUpload(false)
        }
        catch (error){
            setErrorUpload(error.message)
            setLoadingUpload(false)
        }
    }

    const uploadLogoHandler = async (e) => {
        uploadFileHandler(e, 'logo')
    }

    const uploadBannerBackgroundHandler = async (e) => {
        uploadFileHandler(e, 'banner_background_image')
    }

    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [showSnackbarMessage, setShowSnackbarMessage] = useState(false)

    useEffect(() => {
        console.log("useEffect successUpdate: "+successUpdate)
        if (successUpdate){
            setShowSnackbarMessage(true)
            setSnackbarMessage("Profile was updated.")
            setTimeout(function(){ setShowSnackbarMessage(false)}, 3000);
        }
    },[successUpdate])

    useEffect(() => {
        if (!showSnackbarMessage)   dispatch({type: USER_UPDATE_PROFILE_RESET})
    },[dispatch, showSnackbarMessage])

    function apply(){
        props.history.push(`/seller_info`)
    }

    function checked(c){
        return seller_has_swish ? 'checked' : ''
    }

	return (
		<div className="settings">
		 <form className="form" onSubmit={submitHandler}>
            
            
            {
                loading ? <LoadingBox></LoadingBox>
                : error ? <MessageBox variant="danger">{error}</MessageBox>
                :
                <>
                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && (<MessageBox variant="danger">{errorUpdate}</MessageBox>)}
                {successUpdate && <MessageBox variant="success">Profile Updated Successfully</MessageBox>}
                
                {user && (user.is_seller || user.wants_to_be_seller) && (
                    <>
                   
                    <h1 className="p-2 theme-header">My Shop / Settings</h1>

                    <div className="card p-1 form-card">
                        <h3>Shop Name</h3>
                        <label htmlFor="seller_name" className="form-label">Name</label>
                        <input id="seller_name" required type="text" placeholder="Enter Shop Name"
                         value={seller_name} onChange={(e) => set_seller_name(e.target.value)}>
                         </input>
                         <label htmlFor="name_text_color" className="form-label">Name text color</label>
                         <input id="name_text_color" type="text" placeholder="#RRGGBB"
                        value={seller_name_text_color} onChange={(e) => set_seller_name_text_color(e.target.value)}>
                        </input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Address</h3>
                        <label htmlFor="seller_address" className="form-label">Address</label>
                        <input id="seller_address" required type="text" placeholder="Address" value={seller_address} onChange={(e) => set_seller_address(e.target.value)}></input>
                        <label htmlFor="seller_post_number" className="form-label">Postal code</label>
                        <input id="seller_post_number" required type="text" placeholder="Postnr" value={seller_post_number} onChange={(e) => set_seller_post_number(e.target.value)}></input>
                        <label htmlFor="seller_city" className="form-label">City</label>
                        <input id="seller_city" required type="text" placeholder="City" value={seller_city} onChange={(e) => set_seller_city(e.target.value)}></input>
                        <label htmlFor="seller_phone" className="form-label">Phone</label>
                        <input id="seller_phone" required type="text" placeholder="Phone" value={seller_phone} onChange={(e) => set_seller_phone(e.target.value)}></input>
                    </div>
                   
                    <div className="card p-1 form-card">
                        <h3>Organisation number</h3>
                        <input id="seller_organisation_number" required type="text" placeholder="NNNNNN-NNNN" value={seller_organisation_number} onChange={(e) => set_seller_organisation_number(e.target.value)}></input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Status</h3>   
                        {user.is_seller ? <div className="shop-open">Open</div> : 
                        user.wants_to_be_seller ? <div className="pending">Waiting for approval</div>  : 
                        <div className="danger">Closed</div>}
                    </div>

                    <div className="card p-1 form-card">
                        <h3>URL</h3>  
                        <a  className="break-all" href={`https://lundazon.se/seller/${userInfo._id}`}>{`https://lundazon.se/seller/${userInfo._id}`}</a>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Plan</h3>
                        {user.seller.plan}
                    </div>

                    <div className="card p-1 form-card">
                        <h3 >Logo</h3>
                        
                        
                        <label className="logo_switch">
                            <Switch onColor="#44a775" uncheckedIcon={false} checkedIcon={false} onChange={() => set_seller_has_logo(!seller_has_logo)} checked={seller_has_logo} >
                              </Switch>
                        </label>
                        
                       
                        {seller_has_logo && 
                            <>
                                <div>
                                    <Link to={seller_logo}>
                                    <img className="small" src={seller_logo} alt={seller_logo}></img>
                                    </Link>
                                </div>
                                <div>
                                    <input id="sellerLogoImage" type="file" label="Choose Shop Logo" onChange={uploadLogoHandler} ></input>
                                    {loadingUpload && <LoadingBox></LoadingBox>}
                                    {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                                </div>
                            </>
                        }
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Subtitle</h3>
                        <label htmlFor="subtitle" className="form-label">Subtitle</label>
                        <input id="subtitle" type="text" placeholder="Enter Shop Subtitle"
                        value={seller_subtitle} onChange={(e) => set_seller_subtitle(e.target.value)}>
                        </input>
                        <label htmlFor="subtitle_text_color" className="form-label">Text color</label>
                        <input id="subtitle_text_color" type="text" placeholder="#RRGGBB"
                        value={seller_subtitle_text_color} onChange={(e) => set_seller_subtitle_text_color(e.target.value)}>
                        </input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3 >Banner Background Image</h3>
                        <div>
                            <Link to={seller_banner_background_image}>
                            <img className="medium" src={seller_banner_background_image} alt={seller_banner_background_image}></img>
                            </Link>
                        </div>
                        <div>
                            <input id="sellerBackgroundImage" type="file" label="Select Banner Background Image" onChange={uploadBannerBackgroundHandler} ></input>
                            {loadingUpload && <LoadingBox></LoadingBox>}
                            {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                        </div>
                    </div>
                   
                    <div className="card p-1 form-card">
                        <h3>Description</h3>
                        <textarea id="sellerDescription" rows="10" type="text" placeholder="Enter Shop Description"
                         value={seller_description} onChange={(e) => set_seller_description(e.target.value)}>
                         </textarea>
                        <label htmlFor="seller_description_background_color" className="form-label">Background color</label>
                        <input id="seller_description_background_color" size="7" type="text" placeholder="#RRGGBB" value={seller_description_background_color}
                        onChange={(e) => set_seller_description_background_color(e.target.value)}></input>
                       <label htmlFor="seller_description_text_color" className="form-label">Text color</label>
                        <input id="seller_description_text_color" size="7" type="text" placeholder="#RRGGBB" value={seller_description_text_color}
                        onChange={(e) => set_seller_description_text_color(e.target.value)}></input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Products</h3>
                        <label htmlFor="seller_products_background_color" className="form-label">Background color</label>
                            <input id="seller_products_background_color" size="7" type="text" placeholder="#RRGGBB" value={seller_products_background_color}
                            onChange={(e) => set_seller_products_background_color(e.target.value)}></input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Footer</h3>
                        <label htmlFor="seller_footer_background_color" className="form-label">Background color</label>
                            <input id="seller_footer_background_color" size="7" type="text" placeholder="#RRGGBB" value={seller_footer_background_color}
                            onChange={(e) => set_seller_footer_background_color(e.target.value)}></input>
                        <label htmlFor="seller_footer_text_color" className="form-label">Text color</label>
                            <input id="seller_footer_text_color" size="7" type="text" placeholder="#RRGGBB" value={seller_footer_text_color}
                            onChange={(e) => set_seller_footer_text_color(e.target.value)}></input>
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Swish</h3>   

                            <label className="logo_switch">
                            <Switch onColor="#44a775" uncheckedIcon={false} checkedIcon={false} onChange={() => set_seller_has_swish(!seller_has_swish)} checked={seller_has_swish} >
                            </Switch>
                            </label>

                            {/* <label htmlFor="seller_has_swish">Swish</label>  
                            <input id="seller_has_swish" type="checkbox"  checked={checked() ? 'checked' : ''}
                            onChange={(e) => update_seller(e.target.checked ? true : false)}>
                            </input>  */}
                            {seller_has_swish &&
                                <div>
                                    <label htmlFor="seller_swish_number" className="form-label">Swish number</label>
                                    <div>
                                    <input id="seller_swish_number" type="text" 
                                    onChange={(e) => set_seller_swish_number(e.target.value)} disabled={!seller_has_swish} value={seller_swish_number}>
                                    </input> 
                                    </div>
                                </div>
                             }
                           
                    </div>

                    <div className="card p-1 form-card">
                        <h3>Domain</h3>
                        <input id="seller_domain" type="text" placeholder="Domain" value={seller_domain}
                            onChange={(e) => set_seller_domain(e.target.value)}></input>
                        </div>

                    <div>
                        <label/>
                        <button className="primary" type="submit">Update</button>
                    </div>
                    
                    </>
                    
                ) 
                }
             
                </>
            }
        
        </form>
        <div className={showSnackbarMessage ? 'show' : ''}  id="snackbar">{snackbarMessage}</div>
        
    </div>
	)

}